import { IMark, MarkAction, MarkActionType } from './../../types/mark';
import { Dispatch } from "redux"
import MarkService from '../../services/MarkService';



export const addMark = (mark: IMark, userId: string) => {
    return async (dispatch: Dispatch<MarkAction>) => {
        try {
            dispatch({ type: MarkActionType.ADD_MARK, loadingMarks: true });
            const response = await MarkService.addMark(mark, userId);
            dispatch({ type: MarkActionType.ADD_MARK_SUCCESS, loadingMarks: false, message: response.data.message, payload: response.data.mark });
        } catch (error) {
            dispatch({ type: MarkActionType.MARK_ERROR, payload: 'Произошла ошибка при создании метки!' })
        }
    }
}

export const fetchMarks = (userId: string) => {
    return async (dispatch: Dispatch<MarkAction>) => {
        try {
            dispatch({ type: MarkActionType.FETCH_MARKS, loadingMarks: true  });
            const response = await MarkService.fetchMarks(userId);

            dispatch({ type: MarkActionType.FETCH_MARKS_SUCCESS, loadingMarks: false, message: response.data.message, payload: response.data.marks });
        } catch (error) {
            dispatch({ type: MarkActionType.MARK_ERROR, payload: 'Произошла ошибка при загрузке меток!' })
        }
    }
}

export const deleteMark = (markId: string) => {
    return async (dispatch: Dispatch<MarkAction>) => {
        try {
            dispatch({ type: MarkActionType.DELETE_MARK, loadingMarks: true });
           
            const response = await MarkService.deleteMark(markId);
            
            dispatch({ type: MarkActionType.DELETE_MARK_SUCCESS, loadingMarks: false, message: response.data.message, payload: response.data.message });
        } catch (error) {
            dispatch({ type: MarkActionType.MARK_ERROR, payload: 'Произошла ошибка при удалении метки!' })
        }
    }
}
