/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Button, Form, Input, message } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import { useUsersActions } from '../../../hooks/useAction';
import cl from './ChangePassword.module.css';
import { useTranslation } from 'react-i18next';

export const ChangePassword = (props: any) => {
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const { messageChangePassword } = useTypedSelector(state => state.users);
    const { passwordEditModeAccessUser } = useUsersActions();
    const [statusPassword, setStatusPassword] = useState(null)
    const onFinish = (values: any) => {
        // console.log('Received values of form: ', values);
        passwordEditModeAccessUser(props.userId, values.oldPassword, values.newPassword);
        props.offWindow(false);
        //  message.success(messageChangePassword);
    };
    const onReset = () => {
        setStatusPassword(null);
        form.resetFields();
    };

    useEffect(() => {
        setStatusPassword(messageChangePassword);
    }, [messageChangePassword]);

    useEffect(() => {
        switch (statusPassword) {
            case true:
                message.success(t('changedPassword'));
                onReset();
                break;
            case false:
                message.warning(t('erorrPassword'));
                onReset();
                props.offWindow(true);
                break;
            default:
                onReset();
                break;
        }
    }, [statusPassword]);

    return (
        <div className={cl.changePassword}>
            <Form
                form={form}
                name="validate_other"
                onFinish={onFinish}
                labelCol={{ span: 12 }}
              
                layout="horizontal"
                labelAlign="left"
            >
                <Form.Item   >
                    <span className={cl.textPasswordChange}>{t('changePassword')}</span>
                </Form.Item>

                <Form.Item
                    label={t('pass')}
                    name="oldPassword"
                    rules={[{ required: true, message: t('enterPassword') }]}
                    
                >
                    <Input.Password placeholder={t('oldPassIn')} />
                </Form.Item>
                <Form.Item
                    label={t('newPass')}
                    name="newPassword"
                    rules={[{ required: true, message: t('newPassIn') }]}
                >
                    <Input.Password placeholder={t('newPassIn')} />
                </Form.Item>
                <Form.Item
                    label={t('newPassRepeat')}
                    dependencies={['newPassword']}
                    name="newPasswordChange"
                    rules={[{ required: true, message: t('newPassRepeat') },
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                            if (!value || getFieldValue('newPassword') === value) {
                                return Promise.resolve();
                            }
                            return Promise.reject(new Error(t('notPasswordCopy')));
                        },
                    })]}
                
                >
                    <Input.Password
                        placeholder={t('newPassRepeat')}
                        iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                    />
                </Form.Item>
                <Form.Item
                >
                    <Button type="primary" htmlType="submit">
                        {t('change')}
                    </Button>
                </Form.Item>

            </Form>
        </div>
    )
}