import React, { FC } from "react";
import Map from "../../common/Map/Map";
import cl from './MainPage.module.css';
export const MainPage: FC = () => {

    return (
        <Map
            mode={'multi'}
            animalId={null}
            height='100%'
            onCreateLocationMark={null}
            colorPerimetr={null}
            perimetrDataAdd={null} />
    )
}

export default MainPage;