import React, { useEffect, useState } from 'react';
import { Form, Select, Button, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { IMark } from '../../../../types/mark';
import cl from './MarkCreate.module.css';
import bank from "../../../../assets/markImg/bank.svg";
import camera from "../../../../assets/markImg/camera.svg";
import home from "../../../../assets/markImg/home.svg";
import info from "../../../../assets/markImg/info.svg";
import marker from "../../../../assets/markImg/marker.svg";
import paw from "../../../../assets/markImg/paw.svg";
import picture from "../../../../assets/markImg/picture.svg";
import star from "../../../../assets/markImg/star.svg";
import { LocationMark } from '../../../../types/locationMark';
import Map from '../../../../common/Map/Map';

export const MarkCreate = ({ userId, role, addMark, offWindow }) => {
    const [formMarkCreate] = Form.useForm();
    const { t } = useTranslation();

    const [newlocationMark, newsetLocationMark] = useState<LocationMark>({
        latitude: null,
        longitude: null
    });
    const [initValue, setInitValue] = useState<any>({
        latitude: newlocationMark.latitude,
        longitude: newlocationMark.longitude
    });
    const onFinish = (values) => {
        const newMark: IMark = ({
            _id: null,
            name: values.nameMark,
            picture: values.picture ? values.picture : 'info',
            latitude: values.latitude,
            longitude: values.longitude
        })
        addMark(newMark, userId);
        onReset();
        if (offWindow) {
            offWindow(false)
        }
    };

    const onReset = () => {
        formMarkCreate.resetFields();
    };

    useEffect(() => {
        setInitValue({
            latitude: newlocationMark.latitude,
            longitude: newlocationMark.longitude
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newlocationMark]);
    useEffect(() => {
        formMarkCreate.setFieldsValue(initValue)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initValue]);
    const checkLatitude = (_: any, value: number) => {
        if (value > 39 && value < 56) {
            return Promise.resolve();
        }
        return Promise.reject(new Error(t('latErorr')));
    };
    const checkLongitude = (_: any, value: number) => {
        if (value > 45 && value < 88) {
            return Promise.resolve();
        }
        return Promise.reject(new Error(t('longErorr')));
    };

    return (
        <div className={cl.addMark}>
            <div className={cl.textHeaderAddMark}>{t('addMark')}</div>
            <div>
                <div>
                    <Form
                        form={formMarkCreate}
                        name="validate_other"
                        onFinish={onFinish}
                        initialValues={initValue}
                    >
                        <Form.Item
                            name="nameMark"
                            rules={[{ required: true, message: t('erorrMarkName') }]}
                        >
                            <Input placeholder={t('nameMark')} />
                        </Form.Item>

                        <Form.Item
                            name="latitude"
                            rules={[{ validator: checkLatitude }]}
                        >
                            <Input placeholder={t('latitude')} />
                        </Form.Item>
                        <Form.Item
                            name="longitude"
                            rules={[{ validator: checkLongitude }]}
                        >
                            <Input placeholder={t('longitude')} />
                        </Form.Item>
                        <Form.Item name="picture" hasFeedback >
                            <Select placeholder={t('picMark')} defaultValue="info"   size={'large'} className={cl.selectPicture}>
                                <Select.Option value="bank" >
                                    <img width={24} src={bank} alt='not load'  />
                                </Select.Option>
                                <Select.Option value="camera">
                                    <img width={24} src={camera} alt='not load' />
                                </Select.Option>
                                <Select.Option value="marker">
                                    <img width={24} src={marker} alt='not load' />
                                </Select.Option>
                                <Select.Option value="home">
                                    <img width={24} src={home} alt='not load' />
                                </Select.Option>
                                <Select.Option value="paw">
                                    <img width={24} src={paw} alt='not load' />
                                </Select.Option>
                                <Select.Option value="picture">
                                    <img width={24} src={picture} alt='not load' />
                                </Select.Option>
                                <Select.Option value="star">
                                    <img width={24} src={star} alt='not load' />
                                </Select.Option>
                                <Select.Option value="info" >
                                    <img width={24} src={info} alt='not load' />
                                </Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item>
                            {offWindow &&
                                <>
                                    <Button type="primary" className={cl.btnCancelAddMark} onClick={() => { offWindow(false) }} danger>
                                        {t("cancel")}
                                    </Button>
                                    <Button type="primary" htmlType="submit" className={cl.btnAddAnimal} disabled={role === 'guest'}>
                                        {t("add")}
                                    </Button>
                                </>
                            }
                            {!offWindow &&
                                  <Button type="primary" htmlType="submit" className={cl.btnAddAnimal} disabled={role === 'guest'} >
                                  {t('add')}
                              </Button>}
                        
                        </Form.Item>
                    </Form>
                </div>
                {/* <MapSetting height={height} onCreateLocationMark={(newlocationMark: LocationMark) => { newsetLocationMark(newlocationMark) }} mode='mark' color='' clearPolygon={null} isPolygon /> */}
                <div className={cl.mapSettings}>
                    <Map 
                    mode={'settingsMark'} 
                    animalId={null} 
                    height='100%' 
                    onCreateLocationMark={(newlocationMark: LocationMark) => { newsetLocationMark(newlocationMark) }} 
                    perimetrDataAdd={null}
                    colorPerimetr={null}/>
                </div>
            </div>
        </div>
    )
}
