import { ITelemetryState, TelemetryAction, TelemetryActionType, } from '../../types/telemetry';

let initialState: ITelemetryState = {
    telemetry: [],
    telemtrySingle: null,
    telemtryAllAnimal: [],
    geoLayer: [],
    message: '',
    loadingTelemetry: false,
    loadingTelemetryAll: false,
    error: null,
    isSingle: true
};

export const telemetryReducer = (state = initialState, action: TelemetryAction): ITelemetryState => {
    switch (action.type) {
        //запрос телеметрии
        case TelemetryActionType.FETCH_TELEMETRY:
            return { ...state, loadingTelemetry: true, error: null }
        //получение телеметрии
        case TelemetryActionType.SUCCESS_TELEMETRY:
            return { ...state, loadingTelemetry: false, error: null, telemetry: action.payload, geoLayer: action.coords }
        //если еррор
        case TelemetryActionType.ERROR_TELEMETRY:
            return { ...state, loadingTelemetry: false, error: action.payload }
        //запрос всей телеметрии
        case TelemetryActionType.FETCH_TELEMETRY_ALL_ANIMAL:
            return { ...state, loadingTelemetryAll: true, error: null }
        //получение телеметрии
        case TelemetryActionType.SUCCESS_TELEMETRY_ALL_ANIMAL:
            return { ...state, loadingTelemetryAll: false, error: null, telemtryAllAnimal: action.payload }
        case TelemetryActionType.SUCCESS_WSS_TELEMETRY:
            return { ...state, loadingTelemetry: false, error: null, telemetry: [action.payload, ...state.telemetry] }
        //запрос всей телеметрии
        case TelemetryActionType.IS_SINGLE_TELEMETRY:
            return { ...state, loadingTelemetry: false, error: null, isSingle: action.payload }
        default:
            return state;
    }
}