import { IMark } from './../types/mark';
import { AxiosResponse } from "axios";
import $api from "../api/axiosConfig";
import { MarkResponse } from '../models/response/MarkResponse';

export default class MarkService {
    static async addMark(mark: IMark, userId: string): Promise<AxiosResponse<MarkResponse>> {
        const createMark = {
            mark: mark,
            userId: userId
        }
        
        return $api.post('mark/create', createMark);
    }
 
    static async fetchMarks(userId: string): Promise<AxiosResponse<MarkResponse>> {

        return $api.get('mark/getMarks/' + userId);
    }


    static async deleteMark(markId: string): Promise<AxiosResponse<MarkResponse>> {
      
        return $api.delete('mark/delete/' + markId);
    }

}

