import axios from 'axios';
import { environment } from '../config'
export const ApiUrl = environment.httpServer;
const $api = axios.create({
    withCredentials: true,
    baseURL: ApiUrl
});

$api.interceptors.request.use((config) => {
    config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
    config.headers.ContentType = 'multipart/form-data';
  
    return config;
})

export default $api;
