import { IPerimetrState, PerimetrAction, PerimetrActionType } from "../../types/perimetr";

let initialState: IPerimetrState = {
    perimetrs: [],
    perimetr: {
        _id: null,
        name: '',
        polygon: [],
        color: null,
    },
    message: '',
    loadingPerimetr: false,
    error: null
};

export const perimetrsReducer = (state = initialState, action: PerimetrAction): IPerimetrState => {
    switch (action.type) {
        case PerimetrActionType.FETCH_PERIMETRS:
            return { ...state, loadingPerimetr: true, error: null }
        case PerimetrActionType.FETCH_PERIMETRS_SUCCESS:
            return { ...state, loadingPerimetr: false, error: null, perimetrs: action.payload }
        //добавить
        case PerimetrActionType.ADD_PERIMETR:
            return { ...state, loadingPerimetr: true, error: null }
        //добавлено успешно
        case PerimetrActionType.ADD_PERIMETR_SUCCESS:
            return { ...state, loadingPerimetr: false, error: null, perimetr: action.payload }
        default:
            return state;
    }
}
