import React from 'react'
import { TileLayer, LayersControl } from 'react-leaflet';
import cl from '../Map.module.css';
const Layers = () => {
    return (
        <>
            <LayersControl position="topleft" className={cl.layersCntr}>
                <LayersControl.BaseLayer name="Рельеф" >
                    <TileLayer
                        attribution='&copy Astel'
                        url="https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png"
                    />
                </LayersControl.BaseLayer>
                <LayersControl.BaseLayer name="Спутник" checked>
                    <TileLayer
                        attribution='&copy Astel'
                        url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                    />
                </LayersControl.BaseLayer>
            </LayersControl>
        </>
    )
}

export default Layers;