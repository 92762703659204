import React, { useEffect, useState } from 'react';
import { DeleteFilled } from '@ant-design/icons';
import { Button, Modal, Tooltip, List, Avatar, App } from 'antd';
import { FaInfoCircle, FaPlus } from "react-icons/fa";
import { MdOutlineGpsFixed } from "react-icons/md";
import { useTranslation } from 'react-i18next';
import { useTypedSelector } from '../../../../hooks/useTypedSelector';
import { useAnimalActions, useMarkActions, usePerimetrActions } from '../../../../hooks/useAction';
import cl from './AnimalList.module.css';
import clSpinner from '../../../../components/LoginForm/LoginForm.module.css';
import { IAnimal } from '../../../../types/animal';

import { AiFillCodepenSquare } from 'react-icons/ai';
import { GiAnimalHide, GiArcheryTarget } from 'react-icons/gi';
import logo from '../../../../assets/static/djailayLogo.svg';
import { MarkCreate } from '../MarkCreate/MarkCreate';
import { IMark } from '../../../../types/mark';
import { AnimalCreate } from '../AnimalCreate/AnimalCreate';
import ModalWindow from '../../../../common/Modal/Modal';
const { confirm, info } = Modal;

export const AnimalList = ({ userId, valueSearch, onAnimalSelected, isEdit }) => {
    const { t } = useTranslation();
    const { addMark } = useMarkActions();
    const { uploadFile, addAnimal } = useAnimalActions();
    const { animals, loadingAnimals, loadingAddDeleteAnimal } = useTypedSelector(state => state.animal);
    const { fetchAnimals, deleteAnimal } = useAnimalActions();
    const { fetchMarks, deleteMark } = useMarkActions();
    const { fetchPerimetr } = usePerimetrActions();
    const { marks, loadingMarks } = useTypedSelector(state => state.marks);
    const { perimetrs, perimetr } = useTypedSelector(state => state.perimetr);
    const { authResponse } = useTypedSelector(state => state.auth);
    const [isSpinner, setIsSpinner] = useState(loadingAnimals);
    const [role] = useState(authResponse.user.role);
    const [animalSelected, setAnimalSelected] = useState<IAnimal | null>(null);
    const [listMode, setListMode] = useState('animal');

    const [modalActive, setModalActive] = useState<boolean>(false);
    const [modalActiveMark, setModalActiveMark] = useState<boolean>(false);
    const onDeleteMark = async (markId: string | null) => {
        if (markId !== null) {
            deleteMark(markId);
        };
    };

    const onDelete = async (animalId: string | null) => {
        if (animalId !== null) {
            deleteAnimal(animalId);
        };
    };


    useEffect(() => {
        // console.log('render userId')
        fetchAnimals(userId);
        fetchMarks(userId);
        fetchPerimetr(userId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userId]);
    useEffect(() => {

        fetchMarks(userId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingMarks]);
    useEffect(() => {
        //   console.log('render loadingAddDeleteAnimal')
        fetchAnimals(userId);
        onAnimalSelected(null)
        setAnimalSelected(null)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingAddDeleteAnimal]);

    useEffect(() => {
        //  console.log('render animalSelected')
        onAnimalSelected(animalSelected)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [animalSelected]);

    const filtredAnimal = animals.filter(h => {
        return h.name.toLowerCase().includes(valueSearch.toLowerCase()) || h.device.toLowerCase().includes(valueSearch.toLowerCase());
    })

    const infoModal = () => {
        info({
            title: t('activInfo'),
            content: <><div>{t('countAnimals')}{animals.length}</div>
                <div>{t('countMarks')}  {marks.length}</div>
            </>,
            okText: 'Ок',
            onOk() {
            },
        })
    }

    const confirmMarkDelete = (mark: IMark) => {
        confirm({
            title: t('deleteMark?'),
            icon: <DeleteFilled />,
            content: t('mark') + ' ' + mark.name + ' ' + t('beDeleteS'),
            okText: 'Ок',
            cancelText: t('cancel'),
            onOk() {
                onDeleteMark(mark._id)
            },
            onCancel() { },
        });
    }
    const confirmDelete = (animal: IAnimal) => {
        confirm({
            title: t('deleteAnimal?'),
            icon: <DeleteFilled />,
            content: t('animal') + ' ' + animal.name + t('beDelete'),
            okText: 'Ок',
            cancelText: t('cancel'),
            onOk() {
                onDelete(animal._id)
            },
            onCancel() { },
        });
    }
    return (
        <>
            {modalActive &&
                <ModalWindow active={modalActive} setActive={setModalActive}
                    children={
                        <AnimalCreate
                            userId={userId}
                            addAnimal={addAnimal}
                            editAnimal={null}
                            uploadFile={uploadFile}
                            role={role}
                            offWindow={(modalActive: boolean) => { setModalActive(modalActive) }}
                            isAnimalSelected={false}
                            animalSelected={null}
                        />
                    }
                />
            }
            {modalActiveMark &&
                <ModalWindow active={modalActiveMark} setActive={setModalActiveMark}
                    children={
                        <MarkCreate
                            userId={userId}
                            addMark={addMark}
                            role={role}
                            offWindow={(modalActiveMark: boolean) => { setModalActiveMark(modalActiveMark) }}
                        />
                    }
                />
            }
            <div className={cl.btnContainer}>
                <Tooltip placement="topLeft" title={t("addAnimal")} >
                    <Button shape="circle" className={isEdit ? (!modalActive ? cl.btnCreate : '') + ' ' + cl.btn : cl.hidden} onClick={() => { setModalActive(true) }} type="primary" icon={<FaPlus />} disabled={modalActive} />
                </Tooltip>
                <Tooltip placement="topLeft" title={t("addMark")} >
                    <Button shape="circle" className={isEdit ? (!modalActive ? cl.btnMark : '') + ' ' + cl.btn : cl.hidden} onClick={() => { setModalActiveMark(true) }} type="primary" icon={<MdOutlineGpsFixed />} disabled={modalActive} />
                </Tooltip>
                <Tooltip placement="topLeft" title={t("statistic")} >
                    <Button shape="circle" className={isEdit ? (!modalActive ? cl.btnStatistic : '') + ' ' + cl.btn : cl.hidden} onClick={() => { infoModal() }} type="primary" icon={<FaInfoCircle />} disabled={modalActive} />
                </Tooltip>
                <Tooltip placement="topLeft" title={t("cardAnimals")} >
                    <Button className={isEdit ? ((cl.btnAnimalList) + ' ' + (listMode === 'animal' ? (!modalActive ? cl.selectedBtn : '') : '')) : (cl.hidden)} onClick={() => { setListMode('animal') }} type="primary" icon={<GiAnimalHide />} disabled={modalActive} />
                </Tooltip>
                <Tooltip placement="topLeft" title={t("marks")} >
                    <Button className={isEdit ? ((cl.btnMarkList) + ' ' + (listMode === 'mark' ? (!modalActive ? cl.selectedBtn : '') : '')) : (cl.hidden)} onClick={() => { setListMode('mark') }} type="primary" icon={<GiArcheryTarget />} disabled={modalActive} />
                </Tooltip>
                <Tooltip placement="topLeft" title={t("perimetr")} >
                    <Button className={isEdit ? ((modalActive ? cl.btnPerimetrList : '') + ' ' + (listMode === 'perimetr' ? (!modalActive ? cl.selectedBtn : '') : '')) : (cl.hidden)} onClick={() => { setListMode('perimetr') }} type="primary" icon={<AiFillCodepenSquare />} disabled={modalActive} />
                </Tooltip>
                {/* <Radio.Group className={isEdit ? cl.btnStatistic + ' ' + cl.btn : cl.hidden} options={plainOptions} onChange={onChange1} value={value1} /> */}
            </div>
            {listMode === 'animal' &&
                <>
                    {!loadingAnimals &&
                        <List
                            size="small"
                            header={<div className={cl.headerAnimalList}>{t("animals")}</div>}
                            bordered={true}
                            className={isEdit ? cl.listAnimalIsEdit : cl.listAnimal}
                            itemLayout="horizontal"
                            dataSource={filtredAnimal}
                            locale={{ emptyText: 'Нет данных' }}
                            renderItem={(item, index) => (
                                <div className={cl.listItemFlex}>
                                    <List.Item
                                        key={item._id}
                                        className={cl.listAnimalItem}
                                        actions={[
                                            <Button
                                                key={`list-animals-edit-${item._id}`}
                                                onClick={() => { confirmDelete(item) }}
                                                shape="circle" icon={<DeleteFilled />}
                                                danger
                                                className={isEdit ? cl.btnDelete : cl.hidden}
                                                disabled={role === 'guest' || modalActive}>
                                            </Button>]}
                                    >
                                        <List.Item.Meta
                                            avatar={<Avatar src={item.photoAnimal} className={cl.avatarAnimal}/>}
                                            title={<Button type="text" className={isEdit ?(cl.titleAnimalIsEdit + ' ' + (animalSelected?._id === item?._id ? cl.selectedAnimal : '')):(cl.titleAnimal + ' ' + (animalSelected?._id === item?._id ? cl.selectedAnimal : ''))} onClick={() => { setAnimalSelected(item) }} disabled={modalActive}>{item.name}</Button>}
                                        />
                                    </List.Item>
                                </div>
                            )}
                        />
                    }
                    {loadingAnimals &&
                        <div className={clSpinner.spinnerPlace}>
                            <div> <img src={logo} alt="" className={clSpinner.imgLogo} /></div>
                            <div className={clSpinner.spinner}></div>
                            <div className={clSpinner.spinnerText}>{t("load")}</div>
                        </div>

                    }
                </>
            }

            {listMode === 'mark' &&
                <>
                    {!loadingAnimals &&
                        <List
                            size="small"
                            header={<div className={cl.headerAnimalList}>{t("marks")}</div>}
                            bordered={true}
                            className={isEdit ? cl.listAnimalIsEdit : cl.listAnimal}
                            itemLayout="horizontal"
                            dataSource={marks}
                            locale={{ emptyText: 'Нет данных' }}
                            renderItem={(item, index) => (
                                <List.Item
                                    key={item._id}
                                    className={cl.listAnimalItem}
                                    actions={[
                                        <Button
                                            key={`list-marks-edit-${item._id}`}
                                            onClick={() => { confirmMarkDelete(item) }}
                                            shape="circle" icon={<DeleteFilled />}
                                            danger
                                            className={isEdit ? cl.btnDelete : cl.hidden}
                                            disabled={role === 'guest' || modalActive}>
                                        </Button>]}
                                >
                                    <List.Item.Meta
                                        avatar={<Avatar src={item.picture} size={'small'} />}
                                        title={<Button type="text" className={isEdit ?(cl.titleAnimalIsEdit + ' ' + (animalSelected?._id === item?._id ? cl.selectedAnimal : '')):(cl.titleAnimal + ' ' + (animalSelected?._id === item?._id ? cl.selectedAnimal : ''))} disabled={modalActive}>{item.name}</Button>}
                                    />
                                </List.Item>

                            )}
                        />
                    }
                    {isSpinner &&
                        <div className={clSpinner.spinnerPlace}>
                            <div> <img src={logo} alt="" className={clSpinner.imgLogo} /></div>
                            <div className={clSpinner.spinner}></div>
                            <div className={clSpinner.spinnerText}>{t("load")}</div>
                        </div>

                    }
                </>
            }
            {listMode === 'perimetr' &&
                <>
                    {!loadingAnimals &&
                        <List
                            size="small"
                            header={<div className={cl.headerAnimalList}>{t("perimetr")}</div>}
                            bordered={true}
                            className={isEdit ? cl.listAnimalIsEdit : cl.listAnimal}
                            itemLayout="horizontal"
                            dataSource={perimetrs}
                            locale={{ emptyText: 'Нет данных' }}
                            renderItem={(item, index) => (
                                <List.Item
                                    key={item._id}
                                    className={cl.listAnimalItem}
                                    actions={[
                                        <Button key={`list-perimetrs-edit-${item._id}`}
                                            onClick={() => { }}
                                            shape="circle"
                                            icon={<DeleteFilled />}
                                            danger
                                            className={isEdit ? cl.btnDelete : cl.hidden}
                                            disabled={role === 'guest' || modalActive}>
                                        </Button>]}
                                >
                                    <List.Item.Meta
                                        title={<Button type="text" className={cl.titleAnimalPerimetr} disabled={modalActive}>{item.name}</Button>}
                                    />
                                </List.Item>

                            )}
                        />
                    }
                    {isSpinner &&
                        <div className={clSpinner.spinnerPlace}>
                            <div> <img src={logo} alt="" className={clSpinner.imgLogo} /></div>
                            <div className={clSpinner.spinner}></div>
                            <div className={clSpinner.spinnerText}>{t("load")}</div>
                        </div>

                    }
                </>
            }
        </>
    )
}
