import { AuthAction, AuthActionType, IAuthState } from "../../types/auth";

let initialState: IAuthState = {
    authResponse: {
        accessToken: '',
        refreshToken: '',
        user: {
            id: '',
            email: '',
            isActivated: false,
            role: '',
            lastSign: '',
            organizationName: '',
            isModeAccess: false,
            isModeGroup: true,
            languageMode: 'ru'
        },
    },
    isAuth: false,
    loading: false,
    isModeAccess: false,
    isModeGroup: true,
    error: null
};

export const authReducer = (state = initialState, action: AuthAction): IAuthState => {
    switch (action.type) {
        case AuthActionType.CHECK_AUTH:
            return { ...state, loading: true, error: null }
        case AuthActionType.CHECK_AUTH_SUCCESS:
            return { ...state, loading: false, error: null, isAuth: true, isModeAccess: action.payload.user.isModeAccess, authResponse: action.payload }
        case AuthActionType.CHECK_AUTH_ERROR:
            return { ...state, loading: false, error: 'Не авторизованы!' }
        case AuthActionType.LOGIN:
            return { ...state, loading: false, isAuth: false, error: null, authResponse: action.payload }
        case AuthActionType.LOGOUT:
            return { ...state, loading: false, isAuth: false, error: null }
        default:
            return state;
    }
}