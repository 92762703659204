/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import cl from './ChartAnimal.module.css';

import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { ChartLine } from './componentCharts/ChartLine';
export interface ChartData {
    temperature: any;
    timeStmp: any;
}

export const ChartsComponent = ({ height='85%' }) => {
    const { telemetry } = useTypedSelector(state => state.telemetry);
   
    const { t } = useTranslation();
     const [telemetryData, setTelemtryData] = useState([]);
     const [telemetryCountData, setTelemtryCountData] = useState([]);
    useEffect(() => {
        const arrayChart = [];
        const tmpArrChart = [];
        telemetry.map(h => {
            if (h.temperature && (h.deviceType === 'moko.tracker-ble' || h.deviceType === 'moko.tracker-ble-tag' || h.deviceType === 'moko.tracker-archive')) {
                const cD: ChartData = {
                    temperature: parseInt(h.temperature),
                    timeStmp: moment(h.timeStamp).format('hh:mm DD.MM')
                }
                arrayChart.push(cD)
                tmpArrChart.push(parseInt(h.temperature))
            }
        })
        setTelemtryData(arrayChart.reverse())
        setTelemtryCountData(tmpArrChart)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [telemetry]);
    return (
        <>
            {(telemetryData && telemetryData.length > 0) &&
                <div className={cl.chartsContainer} >
                    <div style={{ marginLeft: '2rem', marginTop:'1rem' }}>{t("chartTemp")}</div>
                    <ChartLine telemetryData={telemetryData} telemetryCountData={telemetryCountData}/>
                </div>
            }
            {telemetryData.length === 0 &&
                <div className={cl.chartsContainer} >
                    <div className={cl.notDevice}>{t("notsupportdevice")}</div>
                </div>
            }
            {!telemetryData &&
                <h1>Charts NO DATA</h1>
            }
        </>
    )
}