import { Dispatch } from "redux"
import UsersService from "../../services/UserService";
import { UserAction, UserActionType } from "../../types/users"

export const fetchUsers = () => {
    return async (dispatch: Dispatch<UserAction>) => {
        try {
            dispatch({ type: UserActionType.FETCH_USERS });
            const response = await UsersService.fetchUsers();
            dispatch({ type: UserActionType.FETCH_USERS_SUCCESS, payload: response.data });
        } catch (error) {
            dispatch({ type: UserActionType.FETCH_USERS_ERROR, payload: 'Произошла ошибка при загрузке пользователей!' })
        }
    }
}

export const editModeAccessUser = (id: string) => {
    return async (dispatch: Dispatch<UserAction>) => {
        try {
            dispatch({ type: UserActionType.CHANGE_USER_ACCESS_MODE });
            
            const response = await UsersService.editModeAccessUser(id);
          //  console.log('response editModeAccessUser: ', response)
            dispatch({ type: UserActionType.CHANGE_USER_ACCESS_MODE_SUCCESS, payload: response.data });
        } catch (error) {
            dispatch({ type: UserActionType.FETCH_USERS_ERROR, payload: 'Произошла ошибка при загрузке пользователей!' })
        }
    }
}
export const editModeGroup = (id: string) => {
    return async (dispatch: Dispatch<UserAction>) => {
        try {
            dispatch({ type: UserActionType.CHANGE_USER_GROUP, payload: null });
            
            const response = await UsersService.editModeGroup(id);
          //  console.log('response editModeAccessUser: ', response)
            dispatch({ type: UserActionType.CHANGE_USER_GROUP_SUCCESS, payload: response.data });
        } catch (error) {
            dispatch({ type: UserActionType.FETCH_USERS_ERROR, payload: 'Произошла ошибка при загрузке пользователей!' })
        }
    }
}
export const passwordEditModeAccessUser = (id: string, oldPassword: string, newPassword: string) => {
    return async (dispatch: Dispatch<UserAction>) => {
        try {
            dispatch({ type: UserActionType.CHANGE_USER_PASSWORD, payload: null });
            const response = await UsersService.editPasswordUser(id, oldPassword, newPassword);
            dispatch({ type: UserActionType.CHANGE_USER_PASSWORD_SUCCESS, payload: response.data });
        } catch (error) {
            dispatch({ type: UserActionType.FETCH_USERS_ERROR, payload: 'Произошла ошибка при загрузке пользователей!' })
        }
    }
}

export const languageEditModeAccessUser = (id: string, language: string) => {
    return async (dispatch: Dispatch<UserAction>) => {
        try {
            dispatch({ type: UserActionType.CHANGE_USER_LANGUAGE, payload: null });
            const response = await UsersService.editLanguageUser(id, language);
          
            dispatch({ type: UserActionType.CHANGE_USER_LANGUAGE_SUCCESS, payload: response.data });
        } catch (error) {
            dispatch({ type: UserActionType.FETCH_USERS_ERROR, payload: 'Произошла ошибка при загрузке пользователей!' })
        }
    }
}