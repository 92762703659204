export enum TelemetryHeatMapActionType {
    FETCH_HEAT_MAP_TELEMETRY = 'FETCH_HEAT_MAP_TELEMETRY',
    SUCCESS_HEAT_MAP_TELEMETRY = 'SUCCESS_HEAT_MAP_TELEMETRY',
    ERROR_HEAT_MAP_TELEMETRY = 'ERROR_HEAT_MAP_TELEMETRY'
}

export interface IHeatMapTelemetry {
    _id: null | string;
    devEui: null | string;
    latitude: null | string;
    longitude: null | string;
    timeStamp: null | number | string;
    timeUnix: null | number;
    deviceType: null | string;
}
export interface CoordsHeatMap {
    latitude: Number; //LineString
    longitude: Number;
}
export interface IHeatMapTelemetryState {
    telemetry: IHeatMapTelemetry[];
    telemtryAllAnimal: IHeatMapTelemetry[];
    geoLayerHeatMap: GeoLayerHeatMap[];
    loadingHeatMap: boolean;
    error: null | string;
    message: null | string;
}
export interface GeoLayerHeatMap {
    _id: null | string;
    type: string; //LineString
    name: string;
    coordinates: [CoordsHeatMap[]];
}


export type SubscriberType = (telemetry: IHeatMapTelemetry[]) => void;


type IFetchHeatMapTelemetrysAction = {
    type: TelemetryHeatMapActionType.FETCH_HEAT_MAP_TELEMETRY;
}

type IFetchHeatMapTelemetrySuccessAction = {
    type: TelemetryHeatMapActionType.SUCCESS_HEAT_MAP_TELEMETRY;
    coords: GeoLayerHeatMap[];
}
type IFetchHeatMapTelemetryErrorAction = {
    type: TelemetryHeatMapActionType.ERROR_HEAT_MAP_TELEMETRY;
    payload: string;
}

export type HeatMapTelemetryAction = IFetchHeatMapTelemetrysAction | IFetchHeatMapTelemetrySuccessAction |IFetchHeatMapTelemetryErrorAction;