import { LocationMark } from "./locationMark";

export enum PerimetrActionType {
    ADD_PERIMETR = 'ADD_PERIMETR',
    ADD_PERIMETR_SUCCESS = 'ADD_PERIMETR_SUCCESS',
    FETCH_PERIMETRS = 'FETCH_PERIMETRS',
    FETCH_PERIMETRS_SUCCESS = 'FETCH_PERIMETRS_SUCCESS',
    DELETE_PERIMETR = 'DELETE_PERIMETR',
    DELETE_PERIMETR_SUCCESS = 'DELETE_PERIMETR_SUCCESS',
    PERIMETR_ERROR = 'PERIMETR_ERROR'
}

export interface IPerimetr {
    _id: null | string;
    name: string;
    polygon: LocationMark[];
    color: null | string;
}

export interface IPerimetrState {
    perimetrs: IPerimetr[];
    perimetr: IPerimetr;
    loadingPerimetr: boolean;
    error: null | string;
    message: null | string;
}

type IFetchPerimetrAction = {
    type: PerimetrActionType.FETCH_PERIMETRS;
}
type IPerimetrFetchSuccessAction = {
    type: PerimetrActionType.FETCH_PERIMETRS_SUCCESS;
    payload: IPerimetr[];
}
type IAddPerimetrAction = {
    type: PerimetrActionType.ADD_PERIMETR;
}
type IAddPerimetrSuccessAction = {
    type: PerimetrActionType.ADD_PERIMETR_SUCCESS;
    payload: IPerimetr;
}

type IDeletePerimetrAction = {
    type: PerimetrActionType.DELETE_PERIMETR;
}

type IDeletePerimetrSuccessAction = {
    type: PerimetrActionType.DELETE_PERIMETR_SUCCESS;
    payload: string;
    message: string;
}

export type PerimetrAction = IFetchPerimetrAction | IPerimetrFetchSuccessAction | IAddPerimetrAction | IAddPerimetrSuccessAction | IDeletePerimetrAction | IDeletePerimetrSuccessAction;