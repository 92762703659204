import { AxiosResponse } from "axios";
import $api from "../api/axiosConfig";
import { TelemetryResponse } from '../models/response/TelemetryResponse';

export default class TelemetryService {
    static async fetchTelemetry(animalId: string): Promise<AxiosResponse<TelemetryResponse>> {
        return $api.get('telemetry/getTelemetry/' + animalId);
    }

    static async fetchTelemetryTimeRange(animalId, startDate, endDate): Promise<AxiosResponse<TelemetryResponse>> {
        return $api.get(`telemetry/getTelemetryTimeRange/${animalId}/${startDate}/${endDate} `);
    }
    static async fetchAllAnimalTelemetry(userId: string): Promise<AxiosResponse<TelemetryResponse>> {
         return $api.get('telemetry/getAllAnimalTelemetry/' + userId);
     }
     static async fetchSingleTelemetry(animalId) : Promise<AxiosResponse<TelemetryResponse>> {
        return $api.get('telemetry/fetchSingleTelemetry/' + animalId);
    }
    static async changeTrackModeService(isSingleMode) {
        const returnMode =(isSingleMode === true ? false : true);

        return returnMode
    }
} 


