import { AuthResponse } from './../models/response/AuthResponse';
import { AxiosResponse } from "axios";
import $api from "../api/axiosConfig";

export default class AuthService {
    static async login(email: string, password: string): Promise<AxiosResponse<AuthResponse>> {
        return $api.post<AuthResponse>('auth/login', { email, password })
    }

    static async registration(email: string, password: string): Promise<AxiosResponse<AuthResponse>> {
        return $api.post<AuthResponse>('auth/registration', { email, password })
    }

    static async logout(): Promise<void> {
        return $api.post('auth/logout')
    }

    static async checkAuth(): Promise<AxiosResponse<AuthResponse>> {
        return $api.get('auth/refresh');
    }

    static async editUserModeAccess(isModeAccess: Boolean, userId: String): Promise<AxiosResponse<AuthResponse>> {
        //      console.log('userId:', userId)
       // console.log('editUserModeAccess:', isModeAccess)
        const editUser = {
            userId: userId,
            isModeAccess: isModeAccess,
        }
        return $api.patch('auth/editUserModeAccess', { editUser });
    }

    
}