import { CenterState, IMapState, MapAction, MapActionType } from './../../types/mapTypes';
import L from 'leaflet';
let initCenter: CenterState ={
    latitude: 48.789820,
    longitude: 66.794186,
}
let initialState: IMapState = {
    mapParam: {
        center: [48.789820, 66.794186],
        zoom: 4,
        minZoom: 3,
        maxZoom: 19,
        zoomControl: false,
        screenControl: true,
        // maxBounds: L.latLngBounds(L.latLng(40, 80), L.latLng(40, 80)),
        closePopupOnClick: false,
        layers: L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', {
            attribution:
                'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community',
            maxZoom: 17,
        }), 
    }

};

export const mapReducer = (state = initialState, action: MapAction) => {
    switch (action.type) {
        case MapActionType.INIT_MAP:
            return { ...state }
        default:
            return state;
    }
}