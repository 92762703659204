import React from 'react';
import { Switch, Route, Redirect, Router } from 'react-router-dom';
import { AuthPage } from './pages/AuthPage/AuthPage';
import { LayoutPage } from './pages/Layout/LayoutPage';
import ru_RU from "antd/es/locale/ru_RU";
import { createBrowserHistory } from "history";

export const useRoutes = (isAuth: boolean) => {
    const customHistory = createBrowserHistory();
  //  console.log('customHistory: ', customHistory)
    if (isAuth) {
        return (
                <LayoutPage />
           );
    }
    return (
        <Switch>
            <Route path="/">
                <AuthPage />
                {customHistory.location.pathname &&
                    <Redirect to={customHistory.location.pathname} />
                }
                {!customHistory.location.pathname &&
                    <Redirect to="/main" />
                }
            </Route>
        </Switch>
    );

}
export default useRoutes;