import { IPerimetr, PerimetrAction, PerimetrActionType } from './../../types/perimetr';
import { Dispatch } from "redux"
import PerimetrService from '../../services/PerimetrService';


export const fetchPerimetr = (userId: string) => {
    return async (dispatch: Dispatch<PerimetrAction>) => {
        try {
            dispatch({ type: PerimetrActionType.FETCH_PERIMETRS });
            const response = await PerimetrService.fetchPerimetrs(userId);
            dispatch({ type: PerimetrActionType.FETCH_PERIMETRS_SUCCESS, message: response.data.message, payload: response.data.perimetrs });
        } catch (error) {
            // dispatch({ type: PerimetrActionType.PERIMETR_ERROR, payload: 'Произошла ошибка при загрузке периметра!' })
        }
    }
}


export const addPerimetr = (perimetr: IPerimetr, userId: string) => {
    return async (dispatch: Dispatch<PerimetrAction>) => {
        try {
            dispatch({ type: PerimetrActionType.ADD_PERIMETR });
            const response = await PerimetrService.addPerimetr(perimetr, userId);
            dispatch({ type: PerimetrActionType.ADD_PERIMETR_SUCCESS, message: response.data.message, payload: response.data.perimetr });
        } catch (error) {
            // dispatch({ type: MarkActionType.MARK_ERROR, payload: 'Произошла ошибка при создании метки!' })
        }
    }
}

export const deletePerimetr = (perimetrId: string) => {
    return async (dispatch: Dispatch<PerimetrAction>) => {
        try {
            dispatch({ type: PerimetrActionType.DELETE_PERIMETR, loadingPerimetr: true });
           
            const response = await PerimetrService.deletePerimetr(perimetrId);
            
            dispatch({ type: PerimetrActionType.DELETE_PERIMETR_SUCCESS, loadingPerimetr: false, message: response.data.message, payload: response.data.message });
        } catch (error) {
            // dispatch({ type: PerimetrActionType.MARK_ERROR, payload: 'Произошла ошибка при удалении метки!' })
        }
    }
}