export enum MapActionType {
    INIT_MAP = 'INIT_MAP',
}
export interface IMapState {
    mapParam: {
        center: any,
        zoom: number,
        minZoom: number,
        maxZoom: number,
        zoomControl: boolean,
        screenControl: boolean,
        // maxBounds: L.latLngBounds(L.latLng(40, 80), L.latLng(40, 80)),
        closePopupOnClick: false,
        layers: any, 
    }

}

export interface CenterState {
    latitude: number;
    longitude: number;
}

interface IInitMapAction {
    type: MapActionType.INIT_MAP;
}

export type MapAction = IInitMapAction;