import { IUser } from './../models/IUser';
import { AxiosResponse } from "axios";
import $api from "../api/axiosConfig";

export default class UsersService {
    static async fetchUsers(): Promise<AxiosResponse<IUser[]>> {
        return $api.get('auth/users');
    }
    static async editModeAccessUser(id): Promise<AxiosResponse<IUser>> {
        const editUser = {
            userId: id
        }
        return $api.patch('auth/editUserModeAccess', { editUser });
    }
    static async editModeGroup(id): Promise<AxiosResponse<IUser>> {
        const editUser = {
            userId: id
        }
        return $api.patch('auth/editUserModeGroup', { editUser });
    }
    
    static async editPasswordUser(id, oldPassword, newPassword): Promise<AxiosResponse<Boolean>> {

        const editPasswordUser = {
            userId: id,
            oldPassword: oldPassword,
            newPassword: newPassword
        }
    
        return $api.patch('auth/editPasswordUser', { editPasswordUser });
    }

    static async editLanguageUser(id, language): Promise<AxiosResponse<IUser>> {
        const editLanguageUser = {
            userId: id,
            language: language
        }
       
        return $api.patch('auth/editLanguageUser', { editLanguageUser });
    }
} 
