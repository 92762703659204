/* eslint-disable no-sequences */
import React, { useEffect, useState } from 'react';
import { Line } from '@ant-design/plots';
import { useTranslation } from 'react-i18next';
import { ChartData } from '../Charts';

export const ChartLine = ({ telemetryData, telemetryCountData }) => {
    const { t } = useTranslation();
    const paletteSemanticRed = '#F4664A';
    const brandColor = '#5B8FF9';
    const [minMax, setMinMax] = useState({min: 0, max: 0});
    
    const  getMiAnMax = (str) =>{
        const numbers = str.map(e => +e)
        return {
            min: numbers.reduce((a, v) => (v < a ? a = v : {}, a)),
            max: numbers.reduce((a, v) => (v > a ? a = v : {}, a))
        }
    }
    useEffect(() => {
        setMinMax(getMiAnMax(telemetryCountData))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [telemetryData]);
    const config = {
        padding: 70,
        data: telemetryData,
        xField: 'timeStmp',
        yField: 'temperature',
        label: false,
        // label: telemetryData.length < 30 ? true : false,
        autoFit: true,
        seriesField: '',
        color: (tlm) => {
            if (tlm < 0) {
                return brandColor;
            }
            return paletteSemanticRed;
        },
      
        xAxis: {
            label: {
                autoHide: true,
                autoRotate: false,
                formatter: () => {
                    return null
                }
            },
            alias: t("dateReceiving"),
        },
        yAxis: {
            alias: t("temperatureTooltip"),
            minLimit: minMax.min - 5,
            maxLimit: minMax.max + 5,
            tickInterval: 2,
            label: {
                formatter: (value) => {
                    return parseInt(value)
                }
            },
        },
        tooltip: {
            //  title: t("tooltipInfo"),
            shared: true,
            showContent: true,
            formatter: (datum) => {
                return { name: t("temperatureTooltip"), value: datum.temperature + ' ℃', };
            }
        },
        smooth: true,
          
    };
    return <Line {...config} />;
};
