import { AxiosResponse } from "axios";
import $api from "../api/axiosConfig";
import { HeatMapTelemetryResponse } from "../models/response/HeatTelemetryResponse";

export default class HeatMapTelemetryService {
    static async fetchHeatMapTelemetry(animalId: string, countDay: number): Promise<AxiosResponse<HeatMapTelemetryResponse>> {
        return $api.get('telemetry/getHeatMapTelemetry/' + animalId + '/' + countDay);
    }
} 


