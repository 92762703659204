import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import * as UserActionsCreator from '../store/action-ceators/users.action';
import * as AuthActionsCreator from '../store/action-ceators/auth.action';
import * as AnimalActionsCreator from '../store/action-ceators/animal.action';
import * as TelemetryActionsCreator from '../store/action-ceators/telemetry.action';
import * as TaskAnimalActionsCreator from '../store/action-ceators/taskAnimal.action';
import * as ComentSupportCreator from '../store/action-ceators/commentSupport.action';
import * as MarkActionsCreator from '../store/action-ceators/mark.action';
import * as PerimetrActionsCreator from '../store/action-ceators/perimetr.action';
import * as HeatMapTelemetryActionsCreator from '../store/action-ceators/heatMapTelemetry.action';

export const useUsersActions = () => {
    const dispatch = useDispatch();
    return bindActionCreators(UserActionsCreator, dispatch);
}

export const useAuthActions = () => {
    const dispatch = useDispatch();
    return bindActionCreators(AuthActionsCreator, dispatch);
}

export const useAnimalActions = () => {
    const dispatch = useDispatch();
    return bindActionCreators(AnimalActionsCreator, dispatch);
}

export const useTelemetryActions = () => {
    const dispatch = useDispatch();
    return bindActionCreators(TelemetryActionsCreator, dispatch);
}


export const useTaskAnimalActions = () => {
    const dispatch = useDispatch();
    return bindActionCreators(TaskAnimalActionsCreator, dispatch);
}


export const useComentSupportActions = () => {
    const dispatch = useDispatch();
    return bindActionCreators(ComentSupportCreator, dispatch);
}

export const useMarkActions = () => {
    const dispatch = useDispatch();
    return bindActionCreators(MarkActionsCreator, dispatch);
}

export const usePerimetrActions = () => {
    const dispatch = useDispatch();
    return bindActionCreators(PerimetrActionsCreator, dispatch);
}

export const useHeatMapTelemetryActions = () => {
    const dispatch = useDispatch();
    return bindActionCreators(HeatMapTelemetryActionsCreator, dispatch);
}