import { TaskAnimalResponse } from './../models/response/TaskAnimalResponse';
import { ITaskAnimal } from '../types/taskAnimal';
import { AxiosResponse } from "axios";
import $api from "../api/axiosConfig";

export default class TaskAnimalService {
    static async addTaskAnimal(task: ITaskAnimal, animalId: string): Promise<AxiosResponse<TaskAnimalResponse>> {
        return $api.post('animalTask/create', { task, animalId });
    }

    static async fetchTasksAnimals(animalId: string): Promise<AxiosResponse<TaskAnimalResponse>> {
        return $api.get('animalTask/getTasks/' + animalId);
    }

    static async deleteTaskAnimal(taskAnimalId: string): Promise<AxiosResponse<TaskAnimalResponse>> {
       // console.log('deleteTaskAnimal: ', taskAnimalId)
        return $api.delete('animalTask/delete/' + taskAnimalId);
    }
}

