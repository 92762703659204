import React, { useEffect } from 'react'
import { useMap } from 'react-leaflet'

const CenterMap = ({ center, zoom }) => {
    const map = useMap();

    useEffect(() => {
        if (map) { // Проверка на существование карты
            if (center) {
                map.setView(center, zoom);
            } else {
                map.setView({ latitude: 50, longitude: 70 }, zoom);
            }
        }
    }, [map, center, zoom]);

    return null;
}

export default CenterMap;