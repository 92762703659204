import React, { useState, useEffect } from 'react';
import { Marker, FeatureGroup, Popup, Tooltip, useMap } from 'react-leaflet';
import cl from '../Map.module.css';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import markGreen from "../../../assets/img/markerImg/green.png";
import markGreenShepherd from "../../../assets/img/humanMark/Human_green.png";
import markRedShepherd from "../../../assets/img/humanMark/Human_red.png";
import markShepherdGrey from "../../../assets/img/humanMark/Human_grey.png";
import markShepherdYellow from "../../../assets/img/humanMark/Human_yellow.png";
import markRed from "../../../assets/img/markerImg/red.png";
import yellow from "../../../assets/img/markerImg/yellow.png";
import L from 'leaflet';
import { useTranslation } from 'react-i18next';

import { Divider } from 'antd';
import { TooltipMap } from './ToolTipMap';

const myIconShepherdGreen = L.icon({
    iconUrl: markGreenShepherd,
    iconSize: [36, 36]
});
const myIconShepherdYellow = L.icon({
    iconUrl: markShepherdYellow,
    iconSize: [36, 36]
});
const myIconShepherdGrey = L.icon({
    iconUrl: markShepherdGrey,
    iconSize: [36, 36]
});
const myIconShepherdRed = L.icon({
    iconUrl: markRedShepherd,
    iconSize: [36, 36]
});

const myIconGreen = L.icon({
    iconUrl: markGreen,
    iconSize: [24, 24]
});
var myIconRed = L.icon({
    iconUrl: markRed,
    iconSize: [24, 24],
});
const myIconYellow = L.icon({
    iconUrl: yellow,
    iconSize: [24, 24]
});
const defaultIcon = new L.Icon.Default();

const getCurrentIcon = (animal) => {
    if (!animal) {
        return defaultIcon;
    }

    const now = new Date();
    const diffInMilliseconds = now.getTime() - new Date(animal.lastTelemetry).getTime();
    const diffInHours = diffInMilliseconds / (1000 * 60 * 60);

    if (animal.deviceType === 'dragino.lgt-92.shepherd') {
        if (diffInHours > 6) {
            return myIconShepherdGrey;
        }
        if (diffInHours > 3) {
            return myIconShepherdYellow;
        }
        if (animal.alarm === true) {
            return myIconShepherdRed;
        }
        return myIconShepherdGreen;
    }

    if (diffInHours < 24) {
        return myIconGreen;
    }
    return myIconRed;
};

const MarkersAllAnimal = ({ animals, mode, groupCluster }) => {
    const { t } = useTranslation();

    const [animalMulti, setAnimalMulti] = useState(animals);
    const [animalTooltip, setAnimalTooltip] = useState(mode);
    const [isGroupInto, setIsGroupInto] = useState(mode);

    useEffect(() => {
        setAnimalMulti(animals);
    }, [animals]);

    useEffect(() => {
        setAnimalTooltip(mode)
        setIsGroupInto(mode);
    }, [mode]);

    if (isGroupInto === true && groupCluster === true && animalTooltip === true) {
        return (
            <MarkerClusterGroup
                singleMarkerMode={false}
                spiderfyDistanceMultiplier='6'
                maxClusterRadius='40'
                showCoverageOnHover={true}
                spiderfyOnMaxZoom={true}
                zoomToBoundsOnClick={true}
                chunkedLoading
            > {animalMulti &&
                animalMulti.map((el, i) => (
                    <Marker
                        key={el._id}
                        position={[el.lastLatitude, el.lastLongitude]}
                        icon={getCurrentIcon(el)}
                    >
                        <TooltipMap name={el.name} animalTooltip={animalTooltip} style={{ width: '100px' }} />
                        <Popup minWidth={200} closeButton={false}>
                            <div>
                                <p>{t("alias")} {el.name}</p>
                                <Divider className={cl.dividerAnimalCard} />
                                <p>{t("number")} {el.device}</p>
                                <p>{t("timeSatamp")} {new Date(el.lastTelemetry).toLocaleString('kz-Kz', { hour12: false })}</p>
                                <p>{t("coords")} {el.lastLatitude} - {el.lastLongitude}</p>
                                <p>{t("temperature")} {el.temperature ? el.temperature.toFixed(1) + ' ℃' : t("noData")}</p>
                            </div>

                        </Popup>

                    </Marker>

                ))
                }
            </MarkerClusterGroup>
        )
    } else {
        return (animalMulti.map((el, i) => (
            <Marker
                key={i}
                position={[el.lastLatitude, el.lastLongitude]}
                icon={getCurrentIcon(el)}
            >
                <Popup minWidth={200} closeButton={false}>
                    <div>
                        <p>{t("alias")} {el.name}</p>
                        <Divider className={cl.dividerAnimalCard} />
                        <p>{t("number")} {el.device}</p>
                        <p>{t("timeSatamp")} {new Date(el.lastTelemetry).toLocaleString('kz-Kz', { hour12: false })}</p>
                        <p>{t("coords")} {el.lastLatitude} - {el.lastLongitude}</p>
                        <p>{t("temperature")} {el.temperature ? el.temperature + ' ℃' : t("noData")}</p>
                    </div>
                </Popup>
                <TooltipMap name={el.name} animalTooltip={animalTooltip} />
            </Marker>
        ))
        )
    }

}
export default MarkersAllAnimal;