import React, { useRef, useEffect } from 'react';
import { Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import { useTranslation } from 'react-i18next';
import { Divider } from 'antd';
import cl from '../Map.module.css';

export const MarkersAnimalBot = (props) => {
    const { t } = useTranslation();
    const markerRef = useRef(); // Создаем реф для маркера

    useEffect(() => {
        if (markerRef.current) {
            markerRef.current.openPopup(); // Открываем Popup при монтировании маркера
        }
    }, []); // Пустой массив зависимостей обеспечивает выполнение useEffect только один раз

    const defaultIcon = new L.Icon.Default();

    return (
        <Marker
            ref={markerRef} // Привязываем реф к маркеру
            key={props.animalBotName}
            position={[props?.animalBotCoords.latitude, props?.animalBotCoords.longitude]}
            icon={defaultIcon}
        >
            <Popup minWidth={200} closeButton={false}>
                <div>
                    <p style={{ color: 'red', fontSize: '16px' }}>
                        <b>{t("alertAnimal")}</b>
                    </p>
                    <p>
                        {t("alias")} {props.animalBotName}
                    </p>
                    <Divider className={cl.dividerAnimalCard} />
                </div>
            </Popup>
        </Marker>
    );
};
