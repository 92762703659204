import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Provider } from 'react-redux';
import store from './store/redux-store';
import { ConfigProvider } from "antd";

import { BrowserRouter } from 'react-router-dom';
// настройки плагина
import './i18n';
ReactDOM.render(
  <BrowserRouter>
     <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#285574",
          borderRadius: "0px"
        }
      }}
      
    >
    <Provider store={store}>
      <Suspense fallback={<div>...</div>}>
        <App />
      </Suspense>
    </Provider>
    </ConfigProvider>
  </BrowserRouter>
  ,
  document.getElementById('root')
);


