import React, { useEffect, useState } from 'react'
import {  GeoJSON } from 'react-leaflet';

const Perimetr = ({ perimetrData }) => {
    const [perData, setPerData] = useState(perimetrData);

    useEffect(() => {
        setPerData(perimetrData);
    }, [perimetrData]);

    return (
        <div>
            {perData.length > 0 &&
                perData.map((data) => {
                    let perCoor = JSON.parse(JSON.stringify(data.polygon).replace(/"/g, ""));
                    perCoor.map((dRev, index) => dRev.reverse());

                    const geometry = {
                        type: "Polygon",
                        coordinates: [perCoor],
                    };
                    const color = data.color;
                    return (
                        <GeoJSON key={data._id} data={geometry} pathOptions={{ color: color }} />
                    );
                })}
        </div>
    );
};

export default Perimetr;