import { Dispatch } from "redux"
import AuthService from "../../services/AuthService";
import { AuthAction, AuthActionType } from "../../types/auth";
// import UsersService from "../../services/UserService";
// import { UserAction, UserActionType } from "../../types/users"

export const checkAuth = () => {
    return async (dispatch: Dispatch<AuthAction>) => {
        try {
            dispatch({ type: AuthActionType.CHECK_AUTH, loading: true });
            const response = await AuthService.checkAuth();
            localStorage.setItem('token', response.data.accessToken);
            dispatch({ type: AuthActionType.CHECK_AUTH_SUCCESS,  loading: false, payload: response.data });
        } catch (error) {
            dispatch({ type: AuthActionType.CHECK_AUTH_ERROR, payload: 'Произошла ошибка при проверке аутентификации!' })
        }
    }
}


export const login = (email: string, password: string) => {
    return async (dispatch: Dispatch<AuthAction>) => {
        try {
            dispatch({ type: AuthActionType.LOGIN });
            const response = await AuthService.login(email, password);
            localStorage.setItem('token', response.data.accessToken);
            dispatch({ type: AuthActionType.CHECK_AUTH_SUCCESS, payload: response.data });
        } catch (error) {
            dispatch({ type: AuthActionType.CHECK_AUTH_ERROR, payload: 'Произошла ошибка при проверке аутентификации!' })
        }
    }
}


export const registration = (email: string, password: string) => {
    return async (dispatch: Dispatch<AuthAction>) => {
        try {
            dispatch({ type: AuthActionType.LOGIN });
            const response = await AuthService.registration(email, password);
            dispatch({ type: AuthActionType.CHECK_AUTH_SUCCESS, payload: response.data });
        } catch (error) {
            dispatch({ type: AuthActionType.CHECK_AUTH_ERROR, payload: 'Произошла ошибка при проверке аутентификации!' })
        }
    }
}

export const logout = () => {
    return async (dispatch: Dispatch<AuthAction>) => {
        try {
            dispatch({ type: AuthActionType.LOGOUT });
            await AuthService.logout();
            localStorage.removeItem('token');
        } catch (error) {
            dispatch({ type: AuthActionType.CHECK_AUTH_ERROR, payload: 'Произошла ошибка при проверке аутентификации!' })
        }
    }
}