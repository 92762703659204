export enum MarkActionType {
    ADD_MARK = 'ADD_MARK',
    ADD_MARK_SUCCESS = 'ADD_MARK_SUCCESS',
    FETCH_MARKS = 'FETCH_MARKS',
    FETCH_MARKS_SUCCESS = 'FETCH_MARKS_SUCCESS',
    DELETE_MARK = 'DELETE_MARK',
    DELETE_MARK_SUCCESS = 'DELETE_MARK_SUCCESS',
    MARK_ERROR = 'MARK_ERROR'
}

export interface IMark {
    _id: null | string;
    name: string;
    picture: string;
    latitude: null | number;
    longitude: null | number;
}

export interface IMarkState {
    marks: IMark[];
    mark: IMark;
    loadingMarks: boolean;
    error: null | string;
    message: null | string;
}

type IAddMarkAction = {
    type: MarkActionType.ADD_MARK;
}
type IMarkSuccessAction = {
    type: MarkActionType.ADD_MARK_SUCCESS;
    payload: IMark;
}

type IFetchMarksAction = {
    type: MarkActionType.FETCH_MARKS;
}

type IFetchMarkAction = {
    type: MarkActionType.FETCH_MARKS_SUCCESS;
    payload: IMark[];
}

type IDeleteMarkAction = {
    type: MarkActionType.DELETE_MARK;
}

type IDeleteMarkSuccessAction = {
    type: MarkActionType.DELETE_MARK_SUCCESS;
    payload: string;
    message: string;
}

type IMarkErrorAction = {
    type: MarkActionType.MARK_ERROR;
    payload: string;
}
export type MarkAction = IAddMarkAction | IMarkSuccessAction |
    IFetchMarksAction | IFetchMarkAction |
    IDeleteMarkAction | IDeleteMarkSuccessAction | IMarkErrorAction;