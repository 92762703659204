import React, { FC, useEffect } from 'react';
import './App.css';
import { useRoutes } from './routes';
import { useTypedSelector } from './hooks/useTypedSelector';
import { useAuthActions } from './hooks/useAction';

const App: FC = () => {
    const { isAuth } = useTypedSelector(state => state.auth);
    const { checkAuth } = useAuthActions();
    useEffect(() => {
        checkAuth();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const routes = useRoutes(isAuth);
    return (
        <div>
            {routes}
        </div>
    );
}
export default App;
