import React, { FC, useEffect, useState } from 'react';
import { Button, Layout, Switch, } from 'antd';
import { useAuthActions, useUsersActions } from '../../../hooks/useAction';
import cl from '../LayoutPage.module.css';
import '../LayoutPage.css';
import { Row, Col } from 'antd';
import logo from "../../../assets/static/logo.png";
import { useTranslation } from 'react-i18next';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
const { Header } = Layout;

export const HeaderLayout: FC = () => {
    const { user } = useTypedSelector(state => state.users);
    const { authResponse } = useTypedSelector(state => state.auth);
    const { i18n } = useTranslation();
    const { languageEditModeAccessUser } = useUsersActions();
    const [lang, setLang] = useState(authResponse.user.languageMode)
    const userId = authResponse.user.id;
    // const changeLanguage = (language) => {
    //     i18n.changeLanguage(language);
    // };
    const changeLanguageServer = (language) => {
       languageEditModeAccessUser(userId, language)
    };
    useEffect(() => {
        i18n.changeLanguage(authResponse.user.languageMode);
        if(user.languageMode)
        setLang(user.languageMode)
        i18n.changeLanguage(user.languageMode);
      }, [user]) 

    return (
        <Header className={cl.headerLayout}>
            <div className={cl.internationalizing}>
                <div className={cl.logo}>
                    <img src={logo} alt="" />
                </div>
                <div className={cl.internationalizingBtn}>
                    <Button  className={cl.btnLanguage} type={lang === 'kz'? 'primary':'default'} onClick={() => changeLanguageServer("kz")}>KZ</Button>
                    <Button  className={cl.btnLanguage} type={lang === 'ru'? 'primary':'default'} onClick={() => changeLanguageServer("ru")}>RU</Button>
                </div>
            </div>
        </Header>
    )
}

export default HeaderLayout;