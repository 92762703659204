import React, { useEffect, useState } from 'react';
import { Marker, Popup, useMapEvents } from 'react-leaflet';
export const LocationMarker = ({onCreateLocationMark}) => {
    const [position, setPosition] = useState(null)
    const map = useMapEvents({
        click(e) {
        //    console.log('e lat: ', e.latlng.lat)
      //     console.log('e lng: ', e.latlng.lng)
           onCreateLocationMark(e.latlng)
          // alert("You clicked the map at " + e.latlng);
        },
        dblclick() {
            map.locate()
        },
        locationfound(e) {
            setPosition(e.latlng)
            map.flyTo(e.latlng, map.getZoom())
        },
    })

    return position === null ? null : (
        <Marker position={position}>
            <Popup>Вы здесь</Popup>
        </Marker>
    )
}