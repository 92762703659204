export const environment = {
    httpServer: 'https://djailay.astel.kz:3001/api/',
    wssServer: 'wss://djailay.astel.kz:3080/'
};

// export const environment = {
//     httpServer: 'http://localhost:3001/api/',
//     wssServer: 'ws://localhost:3001'
//   };
  
// export const environment = {
//     httpServer: 'http://d-j.site:3001/api',
//     wssServer: 'ws://localhost:3001'
//   };
export const demoData = {
    demoEmail: 'demo@astel.kz',
    demoPassword: 'demo'
  };

