import React from 'react';
import cl from './ModalCard.module.css';

const ModalCard = ({ children, visible, setVisible }: any) => {
    const rootClasses = [cl.modalCard];
    if(visible){
        rootClasses.push(cl.active);
    }
    return (
        <div className={rootClasses.join(' ')} onClick={()=>{setVisible(false)}}>
            <div className={cl.modalCardContent} onClick={(e) => {e.stopPropagation()}}>
                {children}
            </div>
        </div>
    )
}

export default ModalCard;