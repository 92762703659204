import { Dispatch } from "redux"
import TelemetryService from '../../services/TelemetryService';
import { TelemetryAction, TelemetryActionType } from '../../types/telemetry';

export const fetchTelemetry = (animalId: string) => {
    return async (dispatch: Dispatch<TelemetryAction>) => {
        try {

            dispatch({ type: TelemetryActionType.FETCH_TELEMETRY, loadingTelemetry: true });
            if (animalId) {
                const response = await TelemetryService.fetchTelemetry(animalId);
                //console.log('response: ', response)
                dispatch({ type: TelemetryActionType.SUCCESS_TELEMETRY, loadingTelemetry: false, message: response.data.message, payload: response.data.telemetry, coords: response.data.geoLayerCoords });
            }
            else {
                dispatch({ type: TelemetryActionType.ERROR_TELEMETRY, loadingTelemetry: false, payload: 'Произошла ошибка при загрузке животных!' })
            }
        } catch (error) {
            dispatch({ type: TelemetryActionType.ERROR_TELEMETRY, payload: 'Произошла ошибка при загрузке животных!' })
        }
    }
}

export const fetchSingleTelemetry = (animalId: string) => {
    return async (dispatch: Dispatch<TelemetryAction>) => {
        try {
            dispatch({ type: TelemetryActionType.FETCH_TELEMETRY, loadingTelemetry: true });
           
            if (animalId) {
                const response = await TelemetryService.fetchSingleTelemetry(animalId);
                // console.log('response: ', response)
                dispatch({ type: TelemetryActionType.FETCH_SINGLE_TELEMETRY, loadingTelemetry: false, payload: response.data.telemetrySingle });
            }
            else {
                dispatch({ type: TelemetryActionType.ERROR_TELEMETRY, loadingTelemetry: false, payload: 'Произошла ошибка при загрузке животных!' })
            }
        } catch (error) {
            dispatch({ type: TelemetryActionType.ERROR_TELEMETRY, payload: 'Произошла ошибка при загрузке животных!' })
        }
    }
}

export const fetchTelemetryTimeRange = (animalId: string, startDate: Date, endDate: Date) => {
    return async (dispatch: Dispatch<TelemetryAction>) => {
        try {
            dispatch({ type: TelemetryActionType.FETCH_TELEMETRY, loadingTelemetry: true });
            if (animalId) {
                const response = await TelemetryService.fetchTelemetryTimeRange(animalId, startDate, endDate);
                dispatch({ type: TelemetryActionType.SUCCESS_TELEMETRY, loadingTelemetry: false, message: response.data.message, payload: response.data.telemetry, coords: response.data.geoLayerCoords });
            }
            else {
                dispatch({ type: TelemetryActionType.ERROR_TELEMETRY, loadingTelemetry: false, payload: 'Произошла ошибка при загрузке животных!' })
            }
        } catch (error) {
            dispatch({ type: TelemetryActionType.ERROR_TELEMETRY, payload: 'Произошла ошибка при загрузке животных!' })
        }
    }
}

export const fetchTelemetryAllAnimal = (userId: string) => {
    return async (dispatch: Dispatch<TelemetryAction>) => {
        try {
            dispatch({ type: TelemetryActionType.FETCH_TELEMETRY_ALL_ANIMAL, loadingTelemetry: true });
            if (userId) {
                const response = await TelemetryService.fetchAllAnimalTelemetry(userId);
                dispatch({ type: TelemetryActionType.SUCCESS_TELEMETRY_ALL_ANIMAL, loadingTelemetry: false, message: response.data.message, payload: response.data.telemetry });
            }
            else {
                dispatch({ type: TelemetryActionType.ERROR_TELEMETRY, payload: 'Произошла ошибка при загрузке животных!' })
            }
        } catch (error) {
            dispatch({ type: TelemetryActionType.ERROR_TELEMETRY, payload: 'Произошла ошибка при загрузке животных!' })
        }
    }
}

export const changeTrackMode = (isSingleMode: boolean) => {
    return async (dispatch: Dispatch<TelemetryAction>) => {
        try {
            const response = await TelemetryService.changeTrackModeService(isSingleMode);
            dispatch({ type: TelemetryActionType.IS_SINGLE_TELEMETRY, payload: response });
        } catch (error) {
        }
    }
}


