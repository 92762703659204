import { IMarkState, MarkAction, MarkActionType } from "../../types/mark";

let initialState: IMarkState = {
    marks: [],
    mark: {
        _id: null,
        name: '',
        picture: '',
        latitude: 0,
        longitude: 0
    },
    message: '',
    loadingMarks: false,
    error: null
};

export const marksReducer = (state = initialState, action: MarkAction): IMarkState => {
    switch (action.type) {
        //добавить
        case MarkActionType.ADD_MARK:
            return { ...state, loadingMarks: true, error: null }
        //добавлено успешно
        case MarkActionType.ADD_MARK_SUCCESS:
            return { ...state, loadingMarks: false, error: null, mark: action.payload }
        case MarkActionType.FETCH_MARKS:
            return { ...state, error: null }
        //получение успешно одного животного
        case MarkActionType.FETCH_MARKS_SUCCESS:
            return { ...state, error: null, marks: action.payload }
        // удаление
        case MarkActionType.DELETE_MARK:
            return { ...state, loadingMarks: true }
        // удаление успешно
        case MarkActionType.DELETE_MARK_SUCCESS:
            return { ...state, loadingMarks: false, error: action.payload, message: action.message }
        default:
            return state;
    }
}
