/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Marker, FeatureGroup, Popup } from 'react-leaflet';
import markGreen from "../../../assets/img/markerImg/green.png";
import markGreenShepherd from "../../../assets/img/humanMark/Human_green.png";
import markRedShepherd from "../../../assets/img/humanMark/Human_red.png";
import markShepherdGrey from "../../../assets/img/humanMark/Human_grey.png";
import markShepherdYellow from "../../../assets/img/humanMark/Human_yellow.png";
import markRed from "../../../assets/img/markerImg/red.png";

import yellow from "../../../assets/img/markerImg/yellow.png";
import L from 'leaflet';
import { useTranslation } from 'react-i18next';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import { Divider } from 'antd';
import cl from '../Map.module.css';
export const MarkersAnimalLayer = (props) => {
  const { t } = useTranslation();
  const { telemetry, geoLayer, isSingle } = useTypedSelector(state => state.telemetry);

  const myIconShepherdGreen = L.icon({
    iconUrl: markGreenShepherd,
    iconSize: [36, 36]
  });
  const myIconShepherdYellow = L.icon({
    iconUrl: markShepherdYellow,
    iconSize: [36, 36]
  });
  const myIconShepherdGrey = L.icon({
    iconUrl: markShepherdGrey,
    iconSize: [36, 36]
  });
  const myIconShepherdRed = L.icon({
    iconUrl: markRedShepherd,
    iconSize: [36, 36]
  });

  const myIconGreen = L.icon({
    iconUrl: markGreen,
    iconSize: [24, 24]
  });

  const myIconYellowSmall = L.icon({
    iconUrl: yellow,
    iconSize: [14, 14]
  });
  var myIconRed = L.icon({
    iconUrl: markRed,
    iconSize: [24, 24],

  });
  const myIconYellow = L.icon({
    iconUrl: yellow,
    iconSize: [24, 24]
  });
  const defaultIcon = new L.Icon.Default();
  // console.log('NEW PROPS MarkersAnimalLayer', props.animal)
  const [animalSingle, setAnimalSingle] = useState();
  const [animalMulti, setAnimalMulti] = useState();
  const [isSingleMode, setIsSingleMode] = useState(isSingle);

  const getCurrentIcon = (animal) => {
    if (!animal) {
        return defaultIcon;
    }

    const now = new Date();
    const diffInMilliseconds = now.getTime() - new Date(animal.timeStamp).getTime();
    const diffInHours = diffInMilliseconds / (1000 * 60 * 60);

    if (animal.deviceType === 'dragino.lgt-92.shepherd') {
        if (diffInHours > 6) {
            return myIconShepherdGrey;
        }
        if (diffInHours > 3) {
            return myIconShepherdYellow;
        }
        if (animal.alarm === true) {
            return myIconShepherdRed;
        }
        return myIconShepherdGreen;
    }

    if (diffInHours < 24) {
        return myIconGreen;
    }
    return myIconRed;
};
  useEffect(() => {
    setAnimalSingle(props.animal[0]);
  }, [props.animal[0]])

  useEffect(() => {
    setAnimalMulti(props.animal)
  }, [props.animal]);

  useEffect(() => {
    setIsSingleMode(isSingle)
  }, [isSingle]);
  return (
    <FeatureGroup>
      {(animalSingle && !isSingleMode) &&
        <Marker
          key={animalSingle._id}
          position={[animalSingle?.latitude, animalSingle?.longitude]}
          icon={getCurrentIcon(animalSingle)}
        >
          <Popup minWidth={200} closeButton={false}>
            <div >
              <p>{t("alias")} {animalSingle.name}</p>
              <Divider className={cl.dividerAnimalCard} />
              <p>{t("number")} {animalSingle.devEui}</p>
              <p>{t("timeSatamp")} {new Date(animalSingle?.timeStamp).toLocaleString('kz-Kz', { hour12: false })}</p>
              <p>{t("coords")} {animalSingle?.latitude} - {animalSingle?.longitude}</p>
              <p>{t("temperature")} {animalSingle?.temperature ? animalSingle?.temperature.toFixed(1) + ' ℃' : t("noData")}</p>
            </div>
          </Popup>
        </Marker>

      }
      {(animalMulti && isSingleMode) &&
        animalMulti.map((a, index) =>
          <Marker
            key={a._id}
            position={[a?.latitude, a?.longitude]}
            icon={index === 0 ? getCurrentIcon(a) : myIconYellowSmall}
          >
            <Popup minWidth={200} closeButton={false}>
              <div >
                <p>{t("alias")} {a.name}</p>
                <Divider className={cl.dividerAnimalCard} />
                <p>{t("number")} {a.devEui}</p>
                <p>{t("timeSatamp")} {new Date(a?.timeStamp).toLocaleString('kz-Kz', { hour12: false })}</p>
                <p>{t("coords")} {a?.latitude} - {a?.longitude}</p>
                <p>{t("temperature")} {a.temperature ? a.temperature.toFixed(1) + ' ℃' : t("noData")}</p>
              </div>
            </Popup>
          </Marker>
        )
      }
    </FeatureGroup>
  )
}


