import { UserActionType } from '../../types/users';
import { IUserState, UserAction } from "../../types/users";

let initialState: IUserState = {
    users: [],
    user: {
        id: '',
        email: '',
        isActivated: false,
        role: '',
        lastSign: '',
        organizationName: '',
        isModeAccess: null,
        isModeGroup: true,
        languageMode: null
    },
    messageChangePassword: null,
    messageChangeLanguage: null,
    messageChangeGroup: null,
    loading: false,
    error: null
};

export const usersReducer = (state = initialState, action: UserAction): IUserState => {
    switch (action.type) {
        case UserActionType.FETCH_USERS:
            return { ...state, loading: true, error: null, users: [] }
        case UserActionType.FETCH_USERS_SUCCESS:
            return { ...state, loading: false, error: null, users: action.payload }
        case UserActionType.FETCH_USERS_ERROR:
            return { ...state, loading: false, error: action.payload, users: [] }
        case UserActionType.CHANGE_USER_ACCESS_MODE:
            return { ...state, loading: true, error: null }
        case UserActionType.CHANGE_USER_ACCESS_MODE_SUCCESS:
            return { ...state, loading: true, error: null, user: action.payload }
        case UserActionType.CHANGE_USER_PASSWORD:
            return { ...state, loading: true, error: null, messageChangePassword: null }
        case UserActionType.CHANGE_USER_PASSWORD_SUCCESS:
            return { ...state, loading: true, error: null, messageChangePassword: action.payload }
        case UserActionType.CHANGE_USER_LANGUAGE:
            return { ...state, loading: true, error: null }
        case UserActionType.CHANGE_USER_LANGUAGE_SUCCESS:
            return { ...state, loading: true, error: null, user: action.payload }
        case UserActionType.CHANGE_USER_GROUP:
            return { ...state, loading: true, error: null }
        case UserActionType.CHANGE_USER_GROUP_SUCCESS:
            return { ...state, loading: true, error: null, user: action.payload }
        default:
            return state;
    }
}