import { IUser } from './../models/IUser';
export enum UserActionType {
    FETCH_USERS = 'FETCH_USERS',
    FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS',
    FETCH_USERS_ERROR = 'FETCH_USERS_ERROR',
    CHANGE_USER_ACCESS_MODE = 'CHANGE_USER_ACCESS_MODE',
    CHANGE_USER_ACCESS_MODE_SUCCESS = 'CHANGE_USER_ACCESS_MODE_SUCCESS',
    CHANGE_USER_PASSWORD = 'CHANGE_USER_PASSWORD',
    CHANGE_USER_PASSWORD_SUCCESS = 'CHANGE_USER_PASSWORD_SUCCESS',
    CHANGE_USER_LANGUAGE = 'CHANGE_USER_LANGUAGE',
    CHANGE_USER_LANGUAGE_SUCCESS = 'CHANGE_USER_LANGUAGE_SUCCESS',
    CHANGE_USER_GROUP = 'CHANGE_USER_GROUP',
    CHANGE_USER_GROUP_SUCCESS = 'CHANGE_USER_GROUP_SUCCESS',
}
export interface IUserState {
    users: any[];
    user: IUser;
    loading: boolean;
    error: null | string;
    messageChangePassword: null | boolean;
    messageChangeLanguage: null | boolean;
    messageChangeGroup: null | boolean;
}

interface IFetchUsersAction {
    type: UserActionType.FETCH_USERS;
}
interface IFetchUsersSuccessAction {
    type: UserActionType.FETCH_USERS_SUCCESS;
    payload: any[];
}

interface IFetchUsersErrorAction {
    type: UserActionType.FETCH_USERS_ERROR;
    payload: string;
}

interface IChangeUserAccessMode {
    type: UserActionType.CHANGE_USER_ACCESS_MODE;
}

interface IChangeUserAccessModeSuccess {
    type: UserActionType.CHANGE_USER_ACCESS_MODE_SUCCESS;
    payload: IUser;
}

interface IChangeUserPassword {
    type: UserActionType.CHANGE_USER_PASSWORD;
    payload: null;
}

interface IChangeUserPasswordSuccess {
    type: UserActionType.CHANGE_USER_PASSWORD_SUCCESS;
    payload: any;
}

interface IChangeUserLanguage {
    type: UserActionType.CHANGE_USER_LANGUAGE;
    payload: null;
}
interface IChangeUserLanguageSuccess {
    type: UserActionType.CHANGE_USER_LANGUAGE_SUCCESS;
    payload: any;
}

interface IChangeUserGroup {
    type: UserActionType.CHANGE_USER_GROUP;
    payload: null;
}
interface IChangeUserGroupSuccess {
    type: UserActionType.CHANGE_USER_GROUP_SUCCESS;
    payload: any;
}
export type UserAction = 
IFetchUsersAction | IFetchUsersSuccessAction | IFetchUsersErrorAction | IChangeUserAccessMode | 
IChangeUserAccessModeSuccess | IChangeUserPassword | IChangeUserPasswordSuccess | IChangeUserLanguage | IChangeUserLanguageSuccess | 
IChangeUserGroup | IChangeUserGroupSuccess;