import React, { useEffect, useState } from 'react'
import { GeoJSON } from 'react-leaflet';

const PerimetrAdd = ({ perimetrDataAdd, colorPerimetr }) => {

    const [dataPerAdd, setDataPerAdd] = useState([])
    const [colorPerimetrNew, setColorPerimetrNew] = useState(colorPerimetr)
    useEffect(() => {

        setColorPerimetrNew(colorPerimetr)
    }, [colorPerimetr])

    useEffect(() => {
        if(perimetrDataAdd.length === 0) {
            setDataPerAdd(perimetrDataAdd)
        } else {
            setDataPerAdd([...dataPerAdd, perimetrDataAdd])
        }

    }, [perimetrDataAdd])

    return (
        <div >
            {dataPerAdd.length > 0 &&     
                dataPerAdd.map((data, index) => {
                    const geometry = {
                        type: "Polygon",
                        coordinates: [
                            data
                        ]
                    }
                    const color = colorPerimetrNew ? colorPerimetrNew : 'red';
                    return (
                        <>
                           <GeoJSON key={index} data={geometry} pathOptions={{ color: color }} /> 
                        </>
                    )
                })
            }
        </div>
    )
}


export default PerimetrAdd;