/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Button, Image, Tooltip, Divider, Modal } from 'antd';
import cl from './AnimalCard.module.css';

import { EditFilled } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useTypedSelector } from '../../../../../../hooks/useTypedSelector';
import { IAnimal } from '../../../../../../types/animal';
import defaultAnimalImg from '../../../../../../assets/img/Missingimage.png';
import ModalWindow from '../../../../../../common/Modal/Modal';
import { AnimalCreate } from '../../../AnimalCreate/AnimalCreate';
import { useAnimalActions } from '../../../../../../hooks/useAction';
const { confirm, info } = Modal;
export const AnimalCard = ({ animalSelected, isEdit, userId }) => {
    const { t } = useTranslation();
    const [modalActive, setModalActive] = useState<boolean>(false);
    const { uploadFile, addAnimal, editAnimal } = useAnimalActions();

    const { authResponse } = useTypedSelector(state => state.auth);
    const [role] = useState(authResponse.user.role);

    return (
        <>
            {modalActive &&
                <ModalWindow active={modalActive} setActive={setModalActive}
                    children={
                        <AnimalCreate
                            userId={userId}
                            addAnimal={null}
                            editAnimal={editAnimal}
                            uploadFile={uploadFile}
                            role={role}
                            offWindow={(modalActive: boolean) => { setModalActive(modalActive) }}
                            isAnimalSelected={true}
                            animalSelected={animalSelected} />

                    }

                />
            }
            {(animalSelected) &&
                <div className={cl.cardAnimal}>
                    <div className={cl.animalInfo}>
                        <div className={cl.animalImgButtonContainer}>
                            {!animalSelected.photoAnimal &&
                                <div className={cl.imageContainer}>
                                    <Image
                                        className={cl.imageAnimal}
                                        src={defaultAnimalImg}
                                    />
                                </div>
                            }
                            {animalSelected.photoAnimal &&
                                <div className={cl.imageContainer}>
                                    <div>  <Image
                             
                                        className={cl.imageAnimal}
                                        src={animalSelected.photoAnimal}
                                    /> </div>

                                </div>
                            }
                            <Tooltip placement="topLeft" title={t("changeInfo")}>
                                <Button className={isEdit ? cl.btnEditAnimal : cl.hidden} onClick={() => { setModalActive(true) }} shape="circle" icon={<EditFilled />} />
                            </Tooltip>
                        </div>
                        <div className={cl.animalText}>
                            <div className={cl.inputLabel}>
                                <div ><b>{t("alias")} </b></div>
                                <div className={cl.animalItem}>{animalSelected.name}</div>
                            </div>
                            <div className={cl.inputLabel}>
                                <div ><b>{t("breed")}</b> </div>
                                <div className={cl.animalItem}>{animalSelected.breed ? animalSelected.breed : t("noData")} </div>
                            </div>
                            <div className={cl.inputLabel}>
                                <div ><b>{t("ages")} </b></div>
                                <div className={cl.animalItem}>{animalSelected.age ? (animalSelected.yBirthday < 5 && animalSelected.yBirthday !== 0 ? animalSelected.yBirthday + t("year") + animalSelected.mBirthday + t("month") : animalSelected.yBirthday + t("years") + animalSelected.mBirthday + t("month")) : t("noAge")}</div>
                            </div>
                            <div className={cl.inputLabel}>
                                <div ><b>{t("weight")}</b> </div>
                                <div className={cl.animalItem}>{animalSelected.weight ? animalSelected.weight + ' кг' : t("noWeight")} </div>
                            </div>

                            <Divider className={cl.dividerAnimalCard} />

                            <div className={cl.inputLabel}>
                                <div ><b>{t("number")} </b> </div>
                                <div className={cl.animalItem}>{animalSelected.device.toLocaleUpperCase()}</div>
                            </div>
                            <div className={cl.inputLabel}>
                                <div ><b>{t("model")} </b></div>
                                <div className={cl.animalItem}>{animalSelected.deviceType ? animalSelected.deviceType : t("noType")}</div>
                            </div>
                            <div className={cl.inputLabel}>
                                <div ><b>{t("received")} </b> </div>
                                <div className={cl.animalItem}>{animalSelected.lastTelemetry ? new Date(animalSelected.lastTelemetry).toLocaleString('kz-Kz', { hour12: false }) : t("noData")}</div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

