import React, { useEffect, useRef, useState } from 'react';
import cl from './AnimalCreate.module.css';
import { Form, Select, Slider, Button, Upload, Input, message, DatePicker, DatePickerProps } from 'antd';
import locale from "antd/es/date-picker/locale/ru_RU";
import { UploadOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { IAnimal } from '../../../../types/animal';
import { useTypedSelector } from '../../../../hooks/useTypedSelector';
import defaultAnimalImg from '../../../../assets/img/Missingimage.png'

export const AnimalCreate = ({ userId, addAnimal, editAnimal, uploadFile, role, offWindow, isAnimalSelected, animalSelected }) => {
    const { t } = useTranslation();
    const { animal, animalFotoUpload } = useTypedSelector(state => state.animal);
    const [imgSelect, setImgSelect] = useState<any>(defaultAnimalImg);
    const [isFileOk, setIsFileOk] = useState<boolean>(false);
    const [formAnimalCreate] = Form.useForm();
    const [dateBorn, setDateBorn] = useState(null);
    const [fotoUpload, setFotoUpload] = useState<string>(animalFotoUpload)
    const [initValue, setInitValue] = useState<any>(animalSelected);
    const [isSelected, setIsSelected] = useState<any>(isAnimalSelected);
    const onChangeBornDate: DatePickerProps['onChange'] = (date, dateString) => {
        setDateBorn(date)
    }
    useEffect(() => {
        if (animalSelected && isAnimalSelected) {
            setInitValue({
                name: animalSelected.name,
                device: animalSelected.device,
                deviceType: animalSelected.deviceType,
                photoAnimal: animalSelected.photoAnimal,
                weight: animalSelected.weight,
                age: null
            })
            formAnimalCreate.setFieldsValue(initValue)
        }
    }, [offWindow]);

    useEffect(() => {
        setFotoUpload(animalFotoUpload);
    }, [animalFotoUpload]);
    const normFile = (e) => {
        try {
            e.file.response = 'Файл загружен'
            e.file.error = null
            e.file.status = 'ok'
            return e && e.fileList;
        } catch (error) {
        }
    };
    const onFinish = (values) => {
        let photoAnimal = null;
        if (values.foto !== undefined && isFileOk && values.foto.length > 0) {
            photoAnimal = values.foto[0].name;
        }
        else {
            photoAnimal = null;
        }
        if (isSelected) {
            const newAnimal: IAnimal = ({
                _id: animalSelected._id,
                name: values.name,
                device: values.device,
                deviceType: values.deviceType,
                photoAnimal: (fotoUpload !== null) ? fotoUpload : animalSelected.photoAnimal,
                weight: values.weight,
                age: dateBorn
            })
            editAnimal(newAnimal, userId);
            onReset();
        } else {
            const newAnimal: IAnimal = ({
                _id: null,
                name: values.name,
                device: values.device,
                deviceType: values.deviceType,
                photoAnimal: photoAnimal,
                weight: values.weight,
                age: dateBorn,
                breed: values.breed
            })
            addAnimal(newAnimal, userId);
            onReset();
        }

        if (offWindow) {
            offWindow(false)
        }

    };
    const fileUploadEdit = (fileData: any) => {
        try {

            if (!fileData.file)
                uploadFile(userId, fileData);
            return fileData.file.name;
        } catch (error) {
        }
    }
    const fileUploadAdd = (fileData: any) => {
        try {
            uploadFile(userId, fileData);
            return fileData;
        } catch (error) {
        }
    }
    const onPreview = async file => {
        if (isAnimalSelected) {
            let src = file.url;
            if (!src) {
                src = await new Promise(resolve => {
                    const reader = new FileReader();
                    reader.readAsDataURL(file.originFileObj);
                    reader.onload = () => resolve(reader.result);
                });
            }
            const image = new Image();
            image.src = src;
            setImgSelect(image);
            const imgWindow = window.open(src);
            fileUploadEdit(image)
            imgWindow.document.write(image.outerHTML);
        } else {
            let src = file.url;
            if (!src) {
                src = await new Promise(resolve => {
                    const reader = new FileReader();
                    reader.readAsDataURL(file.originFileObj);
                    reader.onload = () => resolve(reader.result);
                });
            }
            const image = new Image();
            image.src = src;
            const imgWindow = window.open(src);
            imgWindow.document.write(image.outerHTML);
        }

    };
    const onReset = () => {
        formAnimalCreate.resetFields();
    };
    const beforeUpload = (file) => {
        const ERRORTYPE = t('fotoErorrJpgPng');
        const ERRORSIZE = t('fotoErorrSize');
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            setIsFileOk(false)
            message.error(ERRORTYPE);
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            setIsFileOk(false)
            message.error(ERRORSIZE);
        }
        if (isLt2M === true && isJpgOrPng === true) {
            setIsFileOk(true)
        }
        return isJpgOrPng && isLt2M;
    }

    return (
        <div className={cl.addAnimal}>

            <div className={cl.textHeaderAddAnimal}>{t('createProfileAnimal')}</div>

            <Form
                form={formAnimalCreate}
                name="validate_other"
                onFinish={onFinish}
                initialValues={initValue}
            >
                {!isAnimalSelected &&
                    <Form.Item
                        name="foto"
                        valuePropName="fileList"
                        getValueFromEvent={normFile}>
                        <Upload name="fotoAnimal"
                            action={fileUploadAdd}
                            customRequest={fileUploadAdd}
                            listType="picture"
                            maxCount={1}
                            onPreview={onPreview}
                            beforeUpload={beforeUpload}>
                            <Button icon={<UploadOutlined />}>{t('addFoto')}</Button>
                        </Upload>
                    </Form.Item>
                }
                {isAnimalSelected &&
                    <>
                        <Form.Item>
                            <img
                                width={150}
                                src={animalSelected.photoAnimal ? animalSelected.photoAnimal : defaultAnimalImg}
                                alt={defaultAnimalImg}
                            />
                        </ Form.Item >
                        <Form.Item
                            name="foto"
                            label={t('loadFoto')}
                            valuePropName="fileList"
                            getValueFromEvent={normFile}
                        >
                            <Upload name="fotoAnimal"
                                action={fileUploadEdit}
                                customRequest={fileUploadEdit}
                                listType="picture"
                                maxCount={1}
                                onPreview={onPreview}
                                beforeUpload={beforeUpload}
                            >
                                <Button icon={<UploadOutlined />}>{t('changeFoto')}</Button>
                            </Upload>
                        </Form.Item>
                    </>}
                <Form.Item
                    label=""
                    name="device"
                    rules={[{ required: true, message: t('erorrNumberDevice') },
                    ({ getFieldValue }) => ({
                        validator() {
                            switch (getFieldValue('device')?.length) {
                                case 4:
                                    return Promise.resolve();
                                case 16:
                                    return Promise.resolve();
                                default:
                                    return Promise.reject(new Error(t('deviceLengthName')))
                            }
                        },
                    }),
                    ]}
                >
                    <Input placeholder={t('number')} />
                </Form.Item>
                <Form.Item
                    label=""
                    name="name"
                    rules={[{ required: true, message: t('erorrNameAnimal') }]}
                >
                    <Input placeholder={t('animalName')} />
                </Form.Item>
                <Form.Item
                    label=""
                    name="breed"
                >
                    <Input placeholder={t('breed')} />
                </Form.Item>
                <Form.Item
                    name="deviceType"
                    label=""
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: t('erorrModelDevice'),
                        },
                    ]}
                >
                    <Select placeholder={t('selectModelDevice')}>
                        <Select.Option value="dragino.lgt-92">Dragino LGT-92</Select.Option>
                        <Select.Option value="dragino.lgt-92.shepherd">Dragino LGT-92 shepherd</Select.Option>
                        <Select.Option value="vega.lm-1">Vega LM-1</Select.Option>
                        <Select.Option value="moko.tracker-ble">Astel FT-A</Select.Option>
                        <Select.Option value="moko.tracker-ble-tag">Astel BT-A</Select.Option>
                        <Select.Option value="moko.tracker-archive">Astel FT-ARCH</Select.Option>
                        <Select.Option value="none">none</Select.Option>
                    </Select>
                </Form.Item>
                {!animalSelected && !isAnimalSelected &&
                    <>
                        <div>{t('birthday')}</div>
                        <Form.Item name="age"  >
                            <DatePicker locale={locale} onChange={onChangeBornDate} format='DD.MM.YYYY' />
                        </Form.Item>
                    </>
                }
                <div>{t('weightKg')}</div>
                <Form.Item name="weight" >
                    <Slider
                        max={1000}
                        marks={{
                            0: '0',
                            200: '200',
                            400: '400',
                            600: '600',
                            800: '800',
                            1000: '1000',
                        }}
                    />
                </Form.Item>
                <Form.Item

                >
                    {offWindow &&
                        <>
                            <Button type="primary" className={cl.btnCancelAddAnimal} onClick={() => { offWindow(false) }} danger>
                                {t("cancel")}
                            </Button>
                            <Button type="primary" htmlType="submit" className={cl.btnAddAnimal} disabled={role === 'guest'}>
                                {(!animalSelected) ? t("add") : t("change")}
                            </Button>
                        </>
                    }
                    {!offWindow &&
                        <Button type="primary" htmlType="submit" className={cl.btnAddAnimal} disabled={role === 'guest'}>
                            {t("add")}
                        </Button>}

                </Form.Item>
            </Form>
        </div>
    )
}
