import { Dispatch } from "redux"
import { CommentSupportAction, CommentSupportActionType, ICommentSupport } from '../../types/commentSupport';
import CommentSupportService from '../../services/CommentSupportService';

export const addCommentSupport = (comment: ICommentSupport, userId: string) => {
    return async (dispatch: Dispatch<CommentSupportAction>) => {
        try {
            dispatch({ type: CommentSupportActionType.ADD_COMMENT_SUPPORT });
            const response = await CommentSupportService.addCommentSupport(comment, userId);
            dispatch({ type: CommentSupportActionType.ADD_COMMENT_SUPPORT_SUCCESS, message: response.data.message, payload: response.data.commentSupport });
        } catch (error) {
            dispatch({ type: CommentSupportActionType.COMMENT_SUPPORT_ERROR, payload: 'Произошла ошибка при создании комментария!' })
        }
    }
}

export const fetchCommentSupport = (userId: string) => {
    return async (dispatch: Dispatch<CommentSupportAction>) => {
        try {
            dispatch({ type: CommentSupportActionType.FETCH_COMMENT_SUPPORT });
            const response = await CommentSupportService.fetchCommentsSupport(userId);
            dispatch({ type: CommentSupportActionType.FETCH_COMMENT_SUPPORT_SUCCESS, message: response.data.message, payload: response.data.commentsSupport });
        } catch (error) {
            dispatch({ type: CommentSupportActionType.COMMENT_SUPPORT_ERROR, payload: 'Произошла ошибка при получении комментариев!' })
        }
    }
}

export const deleteCommentSupport = (commentId: string) => {
    return async (dispatch: Dispatch<CommentSupportAction>) => {
        try {
            dispatch({ type: CommentSupportActionType.DELETE_COMMENT_SUPPORT });
            const response = await CommentSupportService.deleteCommentSupport(commentId);
            dispatch({ type: CommentSupportActionType.DELETE_COMMENT_SUPPORT_SUCCESS, message: response.data.message, payload: response.data.message });
        } catch (error) {
            dispatch({ type: CommentSupportActionType.COMMENT_SUPPORT_ERROR, payload: 'Произошла ошибка при удалении комментариев!' })
        }
    }
}

