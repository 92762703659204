import React from 'react';
import { Tooltip } from 'react-leaflet';

export const TooltipMap = ({ name, animalTooltip }) => {

    if (animalTooltip) {
        return <Tooltip direction="bottom" offset={[0, 10]} opacity={1} permanent    >
            {name}
        </Tooltip>
    }
    return null
}