import React, { useCallback, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc'; // Импортируем плагин для UTC
import cl from '../Map.module.css';
import locale from "antd/es/date-picker/locale/ru_RU";
import { DatePicker, Button } from 'antd';
import '../../../pages/Layout/LayoutPage.css';
import type { DatePickerProps, RangePickerProps } from 'antd/es/date-picker';
import { useTranslation } from 'react-i18next';
const { RangePicker } = DatePicker;
// Добавляем плагин UTC в dayjs
dayjs.extend(utc);
const RangeTimeChange = ({ animalId, fetchTelemetryTimeRange }) => {
    const { t } = useTranslation();

    const [startDate, setStartDate] = useState(dayjs());
    const [endDate, setEndDate] = useState(dayjs());

    const getTimeRangeTelemetry = () => {
        fetchTelemetryTimeRange(animalId, startDate, endDate)
    }

    const onChange = (
        value: DatePickerProps['value'] | RangePickerProps['value'],
        dateString: [string, string] | string,
    ) => {
        setStartDate(dayjs.utc(dateString[0]))
        setEndDate(dayjs.utc(dateString[1]))
     
    };

    const handleAnimalIdChange = useCallback(() => {
        // Сброс дат при изменении animalId
        setStartDate(dayjs())
        setEndDate(dayjs())
    }, [setStartDate, setEndDate]);

    useEffect(() => {
        handleAnimalIdChange();
    }, [animalId, handleAnimalIdChange]);

    return (
        <div className={cl.rangeTimeCont}>
            <div className={cl.rangeTime}>
                <RangePicker
                    style={{ border: 'solid 1px #81D000', borderRadius: '0px' }}
                    locale={locale}
                    format="YYYY-MM-DD"
                    onChange={onChange}
                    value={[startDate, endDate]}
            
                />
                <Button
                    className={cl.btnRangeTime}
                    htmlType="submit"
                    onClick={getTimeRangeTelemetry}
                    disabled={!startDate && !endDate}
                >
                    {t('done')}
                </Button>
            </div>
        </div>
    )
}
export default RangeTimeChange;
