import React, { useEffect, useRef } from 'react';
import { Layout } from 'antd';
import HeaderLayout from './layout-components/HeaderLayout';
import { FooterLayout } from './layout-components/FooterLayout';
import cl from './LayoutPage.module.css'
import './LayoutPage.css';
import { ContentLayout } from './layout-components/ContentLayout';
import SiderLayout from './layout-components/SiderLayout';
import { Footer } from 'antd/lib/layout/layout';

export const LayoutPage = () => {
    return (
        <Layout style={{height:'98vh'}}>
            <HeaderLayout />
            <Layout>
                <SiderLayout />
                <ContentLayout />
            </Layout>
           {/*   <Footer style={{ textAlign: 'center', backgroundColor: '#285574', color:'white',  zIndex:1, padding:0}}>Astel © 2021 - 2022</Footer> */}
            <FooterLayout /> 
        </Layout>
    )
}

export default LayoutPage;