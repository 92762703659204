export enum TelemetryActionType {
    FETCH_TELEMETRY = 'FETCH_TELEMETRY',
    FETCH_SINGLE_TELEMETRY = 'FETCH_SINGLE_TELEMETRY',
    SUCCESS_TELEMETRY = 'SUCCESS_TELEMETRY',
    ERROR_TELEMETRY = 'ERROR_TELEMETRY',
    SUCCESS_WSS_TELEMETRY = 'SUCCESS_WSS_TELEMETRY',
    FETCH_TELEMETRY_ALL_ANIMAL = 'FETCH_TELEMETRY_ALL_ANIMAL',
    SUCCESS_TELEMETRY_ALL_ANIMAL = 'SUCCESS_TELEMETRY_ALL_ANIMAL',
    ERROR_TELEMETRY_ALL_ANIMAL = 'ERROR_TELEMETRY_ALL_ANIMAL',
    IS_SINGLE_TELEMETRY = 'IS_SINGLE_TELEMETRY'
}

export interface ITelemetry {
    _id: null | string;
    devEui: null | string;
    latitude: null | string;
    longitude: null | string;
    timeStamp: null | number | string | Date;
    timeUnix: null | number;
    deviceType: null | string;
    temperature: null | string;
    alarm: null | boolean;
}

export interface ITelemetryState {
    telemetry: ITelemetry[];
    telemtrySingle: null | ITelemetry;
    telemtryAllAnimal: ITelemetry[];
    isSingle: boolean;
    geoLayer: null | GeoLayer[];
    loadingTelemetry: boolean;
    loadingTelemetryAll: boolean;
    error: null | string;
    message: null | string;
}
export interface GeoLayer {
    _id: null | string;
    type: string; //LineString
    name: string;
    coordinates: [Coords[]];
}

export interface Coords {
    latitude: Number; //LineString
    longitude: Number;
}
export type SubscriberType = (telemetry: ITelemetry[]) => void;


type IFetchTelemetrysAction = {
    type: TelemetryActionType.FETCH_TELEMETRY;
}

type IFetchTelemetrySuccessAction = {
    type: TelemetryActionType.SUCCESS_TELEMETRY;
    payload: ITelemetry[];
    coords: GeoLayer[];
}
type IFetchTelemetryErrorAction = {
    type: TelemetryActionType.ERROR_TELEMETRY;
    payload: string;
}

type IFetchTelemetrySuccessWssAction = {
    type: TelemetryActionType.SUCCESS_WSS_TELEMETRY;
    payload: ITelemetry;
}

type IFetchTelemetrysAllAnimalAction = {
    type: TelemetryActionType.FETCH_TELEMETRY_ALL_ANIMAL;

}
type IFetchTelemetryAllAnimalSuccessAction = {
    type: TelemetryActionType.SUCCESS_TELEMETRY_ALL_ANIMAL;
    payload: ITelemetry[];
}

type IFetchSingleTelemetry = {
    type: TelemetryActionType.FETCH_SINGLE_TELEMETRY;
    payload: ITelemetry;
}

type IisSingleTelemetry = {
    type: TelemetryActionType.IS_SINGLE_TELEMETRY;
    payload: boolean;
}
export type TelemetryAction = IFetchTelemetrysAction | IFetchTelemetrySuccessAction |
    IFetchTelemetryErrorAction | IFetchTelemetrySuccessWssAction | IFetchTelemetrysAllAnimalAction | IFetchTelemetryAllAnimalSuccessAction
    | IFetchSingleTelemetry | IisSingleTelemetry;