/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { MapContainer, Polyline, useMapEvents } from 'react-leaflet'
import markGreen from "../../../assets/img/markerImg/green.png";
import horse from "../../../assets/img/markerImg/horse.png";
import L from 'leaflet';
export const PolylineMapLayer = ({ positions }) => {
   // console.log('props.positions', positions);
   const optionsTracker = {
    color: 'rgb(238, 255, 0)',
    opacity: 0.8,
    smoothFactor: 1,
    weight: 2.5,
    stroke: true,
    dashOffset: 22,
    lineJoin: 'round'
}


    return (
  
          
                <Polyline pathOptions={optionsTracker} positions={positions} />
  
    )
}


