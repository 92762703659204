/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Marker, FeatureGroup, Popup } from 'react-leaflet';
import L from 'leaflet';
import markerCl from "../../../assets/markImg/marker.svg";

export const MarkersMarkLayer = (props) => {
  const { t } = useTranslation();
  return (
    <FeatureGroup>
  
        {(props.marks) &&
          props.marks.map((m, index) =>
            <Marker
              key={m._id}
              position={[m.latitude === 0 ? null : m.latitude, m.longitude === 0 ? null : m.longitude]}
              icon={ L.icon({
                iconUrl: m.picture.substr(-3) !== 'svg' ? markerCl : m.picture,
                iconSize: [32, 32]
              })}
            >
              <Popup minWidth={200} closeButton={false}>
                <div >
                  <p>{t("mark")} {m.name}</p>
                  <p>{t("coords")} {m?.latitude.toFixed(4)} - {m?.longitude.toFixed(4)}</p>
                </div>
              </Popup>
            </Marker>
          )
        }

    </FeatureGroup>
  )
}


