import React from 'react';
import cl from './NotFoundPage.module.css';

export const NotFoundPage = () => {
    return (
        <div id="notfound">
            <div className={cl.notfoundbg} />
            <div className={cl.notfound}>
                <div className={cl.notfound404}>
                    <h1>404</h1>
                </div>
                <h2>Oops! Page Not Found</h2>
            </div>
        </div>
    )
}