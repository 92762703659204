
import React, { FC } from "react";
import Loginform from "../../components/LoginForm/LoginForm";
import cl from './AuthPage.module.css';

export const AuthPage: FC = () => {
  return (
    <div className={cl.containerAuth}>
      <div></div>
      <div className={cl.loginForm}><Loginform /></div>
      <div></div>
    </div>

  )
}

export default AuthPage;