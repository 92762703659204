import React, { useEffect } from 'react';
import cl from './Modal.module.css';

const ModalWindow = ({ active, setActive, children }: any) => {

    return (
        <div className={active ? cl.modal + ' ' + cl.active : cl.modal} >
            <div className={active ? cl.modalContent + ' ' + cl.active : cl.modalContent} onClick={e => e.stopPropagation()}>
                {children}
            </div>
        </div>
    )
}

export default ModalWindow;