import React, { FC, useEffect, useState } from 'react';
import { Form, Input, Button } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import { useAuthActions } from '../../hooks/useAction';
import { FaSignInAlt } from "react-icons/fa";
import cl from './LoginForm.module.css';
import { demoData } from '../../config'
import logo from "../../assets/static/djailayLogo.svg";
const Loginform: FC = () => {
    const { loading } = useTypedSelector(state => state.auth);
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const { login, registration } = useAuthActions();
    const [isSpinner, setIsSpinner] = useState(loading);
    const loginHandler = async () => {
        try {
            login(email, password);
        } catch (e) { }
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const registerHandler = async () => {
        try {
            registration(email, password);
        } catch (e) { }
    };

    const demoHandler = async () => {
        try {
            const demoEmail = demoData.demoEmail;
            const demoPassword = demoData.demoPassword;
            login(demoEmail, demoPassword);
        } catch (e) { }
    }

    useEffect(() => {
        //  console.log('auth before: ', loading)
        setIsSpinner(loading)
        //   console.log('auth after: ', loading)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);
    return (
        <div className={cl.authPage}>
            {isSpinner &&
                <div className={cl.spinnerPlace}>
                    <div> <img src={logo} alt="" className={cl.imgLogo} /></div>
                    <div className={cl.spinner}></div>
                </div>

            }
            {!isSpinner &&
                <div className={cl.formAuth}>
                    <div> <img src={logo} alt="" className={cl.imgLogo} /></div>
                    <Form 
                   >
                        <Form.Item name="email"
                            hasFeedback >
                            <Input prefix={<UserOutlined />}
                                type="email"
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="E-mail"
                                className={cl.inputEmail} />
                        </Form.Item>
                        <Form.Item
                            name="password"
                            hasFeedback
                        >
                            <Input
                                prefix={<LockOutlined />}
                                type="password"
                                placeholder="*******"
                                onChange={e => setPassword(e.target.value)}
                                className={cl.inputEmail}
                            />
                        </Form.Item>
                        <Form.Item  >
                            <div className={cl.btnGroup}>
                                <div className={cl.btnL1}>   <Button type="default" onClick={loginHandler} className={cl.btnL} disabled={loading} icon={<FaSignInAlt style={{ fontSize: '32px' }}/>}>

                                </Button>
                                </div>
                                <div className={cl.btnR1}>
                                    <Button type="default" onClick={demoHandler} className={cl.btnR} disabled={loading}>
                                        Demo
                                    </Button>
                                </div>
                            </div>
                            {/* <Button type="default" onClick={registerHandler} className={cl.btnR} disabled={loading}>
                       Регистрация
                   </Button> */}

                        </Form.Item>
                    </Form>

                </div>
            }

        </div>
    )
}
export default Loginform;