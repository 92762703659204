import React, { useEffect, useMemo, useState } from 'react';
import { LocationMark } from '../../../../types/locationMark';
import { useTypedSelector } from '../../../../hooks/useTypedSelector';
import { IPerimetr } from '../../../../types/perimetr';
import { usePerimetrActions } from '../../../../hooks/useAction';
import { Button, Form, Input, ColorPicker, Row, Space, Col, ColorPickerProps } from 'antd';
import { useTranslation } from 'react-i18next';
import cl from './CreatePerimetrSetting.module.css';
import { Color } from '@material-ui/core';
import { AiFillFormatPainter } from "react-icons/ai";
import Map from '../../../../common/Map/Map';
const { TextArea } = Input;


export const CreatePerimetrSetting = (perimetrs) => {
    const { authResponse } = useTypedSelector(state => state.auth);
    const { addPerimetr } = usePerimetrActions();
    const [formPerimetrCreate] = Form.useForm();
    const { t } = useTranslation();
    const userId = authResponse.user.id;
    const [role] = useState(authResponse.user.role);
    const [colorRgb, setColorRgb] = useState<Color | string | any>('rgb(22, 119, 255)');
    const [formatRgb, setFormatRgb] = useState<ColorPickerProps['format']>('rgb');
    const [isClearPolygon, setIsClearPolygon] = useState<boolean>(false);
    const [newLocationPerimetrData, newSetLocationPerimetrData] = useState<LocationMark[]>([]);


    const [newlocationMark, newsetLocationMark] = useState(null);
    const [initValue, setInitValue] = useState<any>({
        polygon: null
    });
    useEffect(() => {
        if (newlocationMark !== null && newlocationMark) {
            newSetLocationPerimetrData([...newLocationPerimetrData, newlocationMark])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newlocationMark]);
    useEffect(() => {
        setInitValue({
            polygon: newLocationPerimetrData
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newLocationPerimetrData]);
    useEffect(() => {
        formPerimetrCreate.setFieldsValue(initValue)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initValue]);
    const onFinish = (values) => {
        const newPerimetr: IPerimetr = ({
            _id: null,
            name: values.name,
            polygon: newLocationPerimetrData,
            color: typeof colorRgb !== 'string' ? colorRgb.toRgbString() : colorRgb,
        })

        addPerimetr(newPerimetr, userId);
        onReset();
    };
    const clearPolygon = () => {
        setIsClearPolygon(isClearPolygon === false ? true : true);
        setInitValue({
            polygon: []
        })
        newSetLocationPerimetrData([])
    }
    const onReset = () => {
        formPerimetrCreate.resetFields();
        clearPolygon()
    };
    const onChangeColorRgb = (value) => {
        setColorRgb(value.toRgbString())
    }
    useEffect(() => {
        formPerimetrCreate.setFieldsValue(initValue)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initValue]);
    const rgbString = useMemo(
        () => (typeof colorRgb === 'string' ? colorRgb : colorRgb.toRgbString()),
        [colorRgb],
    );
    return (

        <div className={cl.addPerimetr}>
            <div className={cl.textHeaderAddPerimetr}>
                <span className={cl.textHeaderAddPerimetr}>{t('addPerimetr')}:</span>
            </div>
            <Form
                form={formPerimetrCreate}
                name="validate_other"
                onFinish={onFinish}
                initialValues={initValue}

            >
                <Form.Item
                    name="name"
                    rules={[{ required: true, message: t('erorrPerimetrName') }]}
                >
                    <Input placeholder={t('namePerimetr')} />
                </Form.Item>
                <Form.Item
                    name="polygon"
                    rules={[{ required: true, message: t('erorrPolygon') }]}
                >
                    <TextArea placeholder={t('polygonPerimetr')} rows={4} readOnly />
                </Form.Item>
                <Form.Item
                    name="color"
                    label={t('color')}
                >
                    <Row align="middle">
                        <Space>
                            <Col>
                                <ColorPicker
                                    format={formatRgb}
                                    value={colorRgb}
                                    onChange={onChangeColorRgb}
                                    onFormatChange={setFormatRgb}
                                />
                            </Col>
                            <Col>
                                <span>{rgbString}</span>
                            </Col>

                        </Space>
                    </Row>
                </Form.Item>
                <Form.Item
                    label={t('polygonClear')}
                >
                    <Row align="middle">
                        <Space>
                            <Col>
                                <Button icon={<AiFillFormatPainter />} onClick={clearPolygon}></Button>
                            </Col>
                        </Space>
                    </Row>
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit" className={cl.btnAddPerimetr} disabled={role === 'guest'}>
                        {t('add')}
                    </Button>
                </Form.Item>
            </Form>
            <div className={cl.mapSettings}>
                <Map
                    mode={'settingsPerimetr'}
                    animalId={null}
                    height='96.2%'
                    onCreateLocationMark={(newlocationMark: LocationMark) => { newsetLocationMark(newlocationMark) }}
                    perimetrDataAdd={newLocationPerimetrData}
                    colorPerimetr={colorRgb} />
            </div>
        </div>

    )
}