export enum TaskAnimalActionType {
    ADD_TASK_ANIMAL = 'ADD_TASK_ANIMAL',
    ADD_TASK_ANIMAL_SUCCESS = 'ADD_TASK_ANIMAL_SUCCESS',
    TASK_ANIMAL_ERROR = 'TASK_ANIMAL_ERROR',
    FETCH_TASK_ANIMAL = 'FETCH_TASK_ANIMAL',
    FETCH_ANIMAL_SUCCESS = 'FETCH_ANIMAL_SUCCESS',
    DELETE_TASK_ANIMAL = 'DELETE_TASK_ANIMAL',
    DELETE_TASK_ANIMAL_SUCCESS = 'DELETE_TASK_ANIMAL_SUCCESS',
    EDIT_TASK_ANIMAL = 'EDIT_TASK_ANIMAL',
    EDIT_TASK_ANIMAL_SUCCESS = 'EDIT_TASK_ANIMAL_SUCCESS'
}

export interface ITaskAnimal {
    _id: null | string;
    task: null | string;
    timeCreate: null| Date;
}

export interface ITaskAnimalState {
    tasks: ITaskAnimal[];
    task: ITaskAnimal | null;
    loading: boolean;
    error: null | string;
    message: null | string;
}

type IAddTaskAnimalAction = {
    type: TaskAnimalActionType.ADD_TASK_ANIMAL;

}
type IAddTaskAnimalSuccessAction = {
    type: TaskAnimalActionType.ADD_TASK_ANIMAL_SUCCESS;
    payload: ITaskAnimal;
}

type ITaskAnimalErrorAction = {
    type: TaskAnimalActionType.TASK_ANIMAL_ERROR;
    payload: string;
}

type IFetchTaskAnimalAction = {
    type: TaskAnimalActionType.FETCH_TASK_ANIMAL;
}

type IFetchTaskAnimalSuccessAction = {
    type: TaskAnimalActionType.FETCH_ANIMAL_SUCCESS;
    payload: ITaskAnimal[];
}

type IDeleteTaskAnimalAction = {
    type: TaskAnimalActionType.DELETE_TASK_ANIMAL;
}

type IDeleteAnimalSuccessAction = {
    type: TaskAnimalActionType.DELETE_TASK_ANIMAL_SUCCESS;
    payload: string;
    message: string;
}

export type TaskAnimalAction = IAddTaskAnimalAction | IAddTaskAnimalSuccessAction | ITaskAnimalErrorAction | IFetchTaskAnimalAction | IFetchTaskAnimalSuccessAction | IDeleteTaskAnimalAction | IDeleteAnimalSuccessAction;