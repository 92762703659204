import { IAnimal } from '../../types/animal';
import { Dispatch } from "redux"
import { AnimalAction, AnimalActionType } from "../../types/animal";
import AnimalService from '../../services/AnimalService';


export const addAnimal = (animal: IAnimal, userId: string) => {
    return async (dispatch: Dispatch<AnimalAction>) => {
        try {
            dispatch({ type: AnimalActionType.ADD_ANIMALS });
            const response = await AnimalService.addAnimal(animal, userId);
            dispatch({ type: AnimalActionType.ADD_ANIMALS_SUCCESS, message: response.data.message,  payload: response.data.animal });
        } catch (error) {
            dispatch({ type: AnimalActionType.ANIMALS_ERROR, message: false })
        }
    }
}
export const editAnimal = (animal: IAnimal, userId: string) => {
    return async (dispatch: Dispatch<AnimalAction>) => {
        try {
            dispatch({ type: AnimalActionType.EDIT_ANIMAL });
            animal.deviceType = renameReveseDeviceType(animal.deviceType);
            const response = await AnimalService.editAnimal(animal, userId);
            dispatch({ type: AnimalActionType.EDIT_ANIMAL_SUCCESS, message: response.data.message, payload: response.data.animal });
        } catch (error) {
            dispatch({ type: AnimalActionType.ANIMALS_ERROR, message: false })
        }
    }
}
export const uploadFile = (userId: string, fileData: Blob | string | null) => {
    return async (dispatch: Dispatch<AnimalAction>) => {
        try {
            dispatch({ type: AnimalActionType.ADD_ANIMAL_FOTO });
            const response = await AnimalService.uploadFile(userId, fileData);
            dispatch({ type: AnimalActionType.ADD_ANIMAL_FOTO_SUCCESS, payload: response.data.animalFoto });
        } catch (error) {
            dispatch({ type: AnimalActionType.ANIMALS_ERROR, message: false })
        }
    }
}
export const fetchAnimals = (userId: string) => {
    return async (dispatch: Dispatch<AnimalAction>) => {
        try {
            dispatch({ type: AnimalActionType.FETCH_ANIMALS, loadingAnimals: true });
            const response = await AnimalService.fetchAnimals(userId);
            for (const item of response.data.animals) {
                item.deviceType = await renameDeviceType(item.deviceType);

              }
      
            dispatch({ type: AnimalActionType.ANIMALS_SUCCESS, loadingAnimals: false, message: response.data.message, payload: response.data.animals });
        } catch (error) {
            dispatch({ type: AnimalActionType.ANIMALS_ERROR, message: false })
        }
    }
}

export const fetchAnimal = (animalId: string) => {
    return async (dispatch: Dispatch<AnimalAction>) => {
        try {
            dispatch({ type: AnimalActionType.FETCH_ANIMAL });
            const response = await AnimalService.fetchAnimal(animalId);
            response.data.animal.deviceType = await renameDeviceType(response.data.animal.deviceType);
            dispatch({ type: AnimalActionType.ANIMAL_SUCCESS, payload: response.data.animal });
        } catch (error) {
            dispatch({ type: AnimalActionType.ANIMALS_ERROR, message: false })
        }
    }
}


export const deleteAnimal = (animalId: string) => {
    return async (dispatch: Dispatch<AnimalAction>) => {
        try {
            dispatch({ type: AnimalActionType.DELETE_ANIMAL });
            const response = await AnimalService.deleteAnimal(animalId);
            dispatch({ type: AnimalActionType.DELETE_ANIMAL_SUCCESS, message: response.data.message, payload: response.data.message });
        } catch (error) {
            dispatch({ type: AnimalActionType.ANIMALS_ERROR, message: false })
        }
    }
}



const renameDeviceType = (deviceType: string) => {
    switch (deviceType.trim()) {
        case 'dragino.lgt-92':
            return deviceType = 'Dragino LGT-92';
        case 'dragino.lgt-92.shepherd':
            return deviceType = 'Dragino LGT-92 shepherd';
        case 'vega.lm-1':
            return deviceType = 'Vega LM-1';
        case 'moko.tracker-ble':
            return deviceType = 'Astel FT-A';
        case 'moko.tracker-ble-tag':
            return deviceType = 'Astel BT-A';
        case 'moko.tracker-archive':
            return deviceType = 'Astel FT-ARCH';
        default:
            return deviceType = 'unknown';
    }
}

const renameReveseDeviceType = (deviceType: string) => {
    switch (deviceType) {
        case 'Dragino LGT-92':
            return deviceType = 'dragino.lgt-92';
        case 'Dragino LGT-92 shepherd':
            return deviceType = 'dragino.lgt-92.shepherd';
        case 'Vega LM-1':
            return deviceType = 'vega.lm-1';
        case 'Astel FT-A':
            return deviceType = 'moko.tracker-ble';
        case 'Astel BT-A':
            return deviceType = 'moko.tracker-ble-tag';
        case 'Astel FT-ARCH':
            return deviceType = 'moko.tracker-archive';
        default:
            return deviceType;
    }
}