import { IHeatMapTelemetryState, HeatMapTelemetryAction, TelemetryHeatMapActionType, } from '../../types/heatMapTelemetry';

let initialState: IHeatMapTelemetryState = {
    telemetry: [],
    telemtryAllAnimal: [],
    geoLayerHeatMap: [],
    message: '',
    loadingHeatMap: false,
    error: null
};

export const heatMapTelemetryReducer = (state = initialState, action: HeatMapTelemetryAction): IHeatMapTelemetryState => {
    switch (action.type) {
        //запрос телеметрии
        case TelemetryHeatMapActionType.FETCH_HEAT_MAP_TELEMETRY:
            return { ...state, loadingHeatMap: true, error: null }
        //получение телеметрии
        case TelemetryHeatMapActionType.SUCCESS_HEAT_MAP_TELEMETRY:
            return { ...state, loadingHeatMap: false, geoLayerHeatMap: action.coords }
        //если еррор
        case TelemetryHeatMapActionType.ERROR_HEAT_MAP_TELEMETRY:
            return { ...state, loadingHeatMap: false, error: action.payload }
        default:
            return state;
    }
}