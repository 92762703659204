import React, { useEffect, useState } from 'react';
import { Marker, Popup, useMapEvents } from 'react-leaflet';
export const LocationMarkerPerimetr = ({onCreateLocationMark}) => {
    const [position, setPosition] = useState(null)
    const map = useMapEvents({
        click(e) {
           // console.log('LocationMarkerPerimetr e lat: ', e.latlng)
            const markPer = [ e.latlng.lng, e.latlng.lat,]
           onCreateLocationMark(markPer)
           setPosition(e.latlng)
          // alert("You clicked the map at " + e.latlng);
        },
        dblclick() {
            map.locate()
        },
        locationfound(e) {
          //  console.log('locationfound e.latlng: ', e.latlng) 
            map.flyTo(e.latlng, map.getZoom())
        },
    })

    return position === null ? null : (

        <Marker position={position}>
            <Popup>Последняя выбранная точка</Popup>
        </Marker>
    )
}