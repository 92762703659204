import React from 'react';
import Map from '../../common/Map/Map';

export const HeatMapPage = () => {

    return (
        <Map
            mode={'heatMap'}
            height='100%'
            animalId={null}
            onCreateLocationMark={null}
            colorPerimetr={null}
            perimetrDataAdd={null} />
    )
}