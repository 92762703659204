import { Dispatch } from "redux"
import HeatMapTelemetryService from "../../services/HeatMapTelemetryService";
import { HeatMapTelemetryAction, TelemetryHeatMapActionType } from '../../types/heatMapTelemetry';

export const heatMapFetchTelemetry = (userId: string, countDay: number) => {
    return async (dispatch: Dispatch<HeatMapTelemetryAction>) => {
        try {
            dispatch({ type: TelemetryHeatMapActionType.FETCH_HEAT_MAP_TELEMETRY, loadingHeatMap: true });
            if (userId) {
                const response = await HeatMapTelemetryService.fetchHeatMapTelemetry(userId, countDay);
                dispatch({ type: TelemetryHeatMapActionType.SUCCESS_HEAT_MAP_TELEMETRY, loadingTelemetry: false,  coords: response.data.geoLayerCoords });
            }
            else {
                dispatch({ type: TelemetryHeatMapActionType.ERROR_HEAT_MAP_TELEMETRY, loadingTelemetry: false, payload: 'Произошла ошибка при загрузке животных!' })
            }
        } catch (error) {
            dispatch({ type: TelemetryHeatMapActionType.ERROR_HEAT_MAP_TELEMETRY, payload: 'Произошла ошибка при загрузке животных!' })
        }
    }
}



