import React, { useEffect, useState } from 'react';
import { MapContainer, ZoomControl, Marker, Popup, } from 'react-leaflet';
import Layers from './componentsMap/Layers';
import cl from './Map.module.css';
import CenterMap from './componentsMap/CenterMap'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { useHeatMapTelemetryActions, useMarkActions, usePerimetrActions, useTelemetryActions } from '../../hooks/useAction'
import Perimetr from './componentsMap/Perimetr';
import { FullscreenControl } from "react-leaflet-fullscreen";
import "react-leaflet-fullscreen/dist/styles.css";
import { Button, Input, Select, Switch } from 'antd';
import confirm from 'antd/lib/modal/confirm';
import { BiCloudDownload } from "react-icons/bi";
import { SearchOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import MarkersAllAnimal from './componentsMap/MarkersAllAnimal';
import { MarkersMarkLayer } from './componentsMap/MarkersMarkLayer';
import PolylineMeasurer from './componentsMap/PolylineMeasure';
import { HeatMapLayer } from './componentsMap/HeateMapLayer';
import RangeTimeChange from './componentsMap/RangeTimeChange.component';
import { MarkersAnimalLayer } from './componentsMap/MarkersAnimalLayer.component';
import { PolylineMapLayer } from './componentsMap/PolylineMapLayer.component';
import { ChartsComponent } from '../../components/Charts/Charts';
import { LocationMarker } from './componentsMap/LocationMarker';
import PerimetrAdd from './componentsMap/PerimetrAdd';
import { LocationMarkerPerimetr } from './componentsMap/LocationMarkerPerimetr';

const innerBounds = [[10, 60], [70, 90]];
const Map = ({ mode, animalId, height, onCreateLocationMark, perimetrDataAdd, colorPerimetr }) => {
    const { t } = useTranslation();
    const { authResponse } = useTypedSelector(state => state.auth);
    const userId = authResponse.user.id;
    const { perimetrs } = useTypedSelector(state => state.perimetr);
    const { marks } = useTypedSelector(state => state.marks);
    const { fetchPerimetr } = usePerimetrActions();
    const { fetchMarks } = useMarkActions();
    const { fetchTelemetry, fetchTelemetryTimeRange, changeTrackMode } = useTelemetryActions();
    const [isGroup, setIsGroup] = useState(null);

    //Все животные
    const { fetchTelemetryAllAnimal } = useTelemetryActions();
    const { telemetry, geoLayer, telemtryAllAnimal, loadingTelemetry, isSingle, loadingTelemetryAll } = useTypedSelector(state => state.telemetry);
    // Тепловая карта
    const { heatMapFetchTelemetry } = useHeatMapTelemetryActions();
    const { geoLayerHeatMap, loadingHeatMap } = useTypedSelector(state => state.heatMapTelemetry);


    const [telemetryAlone, setTelemetryAlone] = useState([]);

    const [countDayHeatMap, setIsCountDayHeatMap] = useState('7');
    const [isSpinner, setIsSpinner] = useState(loadingHeatMap);
    const [geoLayerHeatMapData, setGeoLayerHeatMapData] = useState([]);

    const [isHeatMap, setIsHeatMap] = useState(false);
    const [isStatMode, setIsStatMode] = useState(false);
    const [perimetrData, setPerimetrData] = useState([]);

    const [marksData, setMarksData] = useState([]);
    const [valueSearch, setValueSearch] = useState('');
    const [isTooltip, setIsTooltip] = useState(true);
    const [isMark, setIsMark] = useState(true);
    const [centerMap, setCenterMap] = useState({ latitude: 50, longitude: 70 })
    const [zoom, setZoom] = useState(6); // Default zoom level
    const [newLocationMarkMap, newSetLocationMarkMap] = useState({
        latitude: null,
        longitude: null
    });
    const [markLoc, setMarkLoc] = useState({
        lat: null,
        lon: null
    });
    const [perimetrMarkLoc, setPerimetrMarkLoc] = useState();
    const [newLocationPerimetrMarkMap, newSetLocationPerimetrMarkMap] = useState();
    const onTelemetryToggle = () => {
        setIsTooltip(isTooltip === true ? false : true);
    }

    const onHeatMapToggle = () => {
        setIsHeatMap(isHeatMap === true ? false : true);
    }
    const onMarksToggle = () => {
        setIsMark(isMark === true ? false : true);
    }
    const onSoloMultiToggle = () => {
        changeTrackMode(isSingle);
    }
    const onStatisticToggle = () => {
        setIsStatMode(isStatMode === true ? false : true);
    }

    useEffect(() => {
        newSetLocationMarkMap({
            latitude: markLoc.lat,
            longitude: markLoc.lng
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [markLoc]);
    function isValidCoordinate(coord) {
        return typeof coord === 'number' && !isNaN(coord) && coord !== 0;
    }
    useEffect(() => {
        newSetLocationPerimetrMarkMap(perimetrMarkLoc)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [perimetrMarkLoc]);
    useEffect(() => {
        switch (mode) {
            case 'settingsMark':
                onCreateLocationMark(newLocationMarkMap)
                break;
            case 'settingsPerimetr':

                onCreateLocationMark(newLocationPerimetrMarkMap)
                break;
            default:
                break;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newLocationMarkMap, newLocationPerimetrMarkMap]);
    useEffect(() => {
        setIsGroup(authResponse.user.isModeGroup)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authResponse.user.isModeGroup]);

    useEffect(() => {
        fetchPerimetr(userId)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        fetchMarks(userId)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setPerimetrData(perimetrs)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchPerimetr]);

    useEffect(() => {
        setMarksData(marks)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchMarks]);

    useEffect(() => {
        switch (mode) {
            case 'multi':
                fetchTelemetryAllAnimal(userId);
                break;
            case 'heatMap':
                heatMapFetchTelemetry(userId, 1);
                break;
            case 'alone':
                fetchTelemetry(animalId);
                break;

            default:
                break;
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mode]);
    useEffect(() => {
        fetchTelemetry(animalId);
    }, [animalId]);

    useEffect(() => {
        setTelemetryAlone(telemetry)
    }, [fetchTelemetry]);

    useEffect(() => {
        if (telemetryAlone.length > 0)
            setCenterMap({ latitude: telemetryAlone[0]?.latitude, longitude: telemetryAlone[0]?.longitude })
    }, [telemetryAlone]);

    useEffect(() => {
        // Проверяем, что есть данные в массиве telemtryAllAnimal
        if (telemtryAllAnimal && telemtryAllAnimal.length > 0) {
            const validCoordinates = telemtryAllAnimal.filter(point => isValidCoordinate(point.latitude) && isValidCoordinate(point.longitude));
            if (validCoordinates.length > 0) {
                const averageLatitude = validCoordinates.reduce((sum, point) => sum + point.latitude, 0) / validCoordinates.length;
                const averageLongitude = validCoordinates.reduce((sum, point) => sum + point.longitude, 0) / validCoordinates.length;
                setCenterMap({ latitude: averageLatitude, longitude: averageLongitude });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [telemtryAllAnimal]);

    useEffect(() => {
        setGeoLayerHeatMapData(geoLayerHeatMap);
        setIsSpinner(loadingHeatMap);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [geoLayerHeatMap]);

    useEffect(() => {
        if (geoLayerHeatMapData[0]) {
            let lat = 0;
            let long = 0;
            for (let index = 0; index < geoLayerHeatMapData[0].length; index++) {
                const element = geoLayerHeatMapData[0][index];
                switch (index) {
                    case 0:
                        lat = element;
                        break;
                    case 1:
                        long = element;
                        break;
                    default:
                        break;
                }
            }
            setCenterMap({ latitude: lat, longitude: long })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [geoLayerHeatMapData]);

    const confirmUploadHeatMapData = () => {
        confirm({
            title: t('confirmUploadHeatMapData'),
            icon: <BiCloudDownload />,
            okText: 'Ок',
            cancelText: t('cancel'),
            onOk() {
                getHeatMap()
            },
            onCancel() { },
        });
    }
    const handleChange = (value) => {
        setIsCountDayHeatMap(value);
    };
    const getHeatMap = () => {
        heatMapFetchTelemetry(userId, countDayHeatMap);
    }
    const filtredTelemetryData = telemtryAllAnimal.filter(h => {
        return h.name.toLowerCase().includes(valueSearch.toLowerCase()) || h.device.toLowerCase().includes(valueSearch.toLowerCase());
    })

    return (
        <div className={
            ((!loadingTelemetryAll && telemtryAllAnimal.length !== 0 && mode === 'multi')
                || (!loadingHeatMap && geoLayerHeatMapData.length !== 0 && mode === 'heatMap')
                || (!loadingTelemetry && telemetry.length !== 0 && mode === 'alone')
                || mode === 'settingsMark' || mode === 'settingsPerimetr')
                ? ((
                    mode === 'multi' ? cl.flexMap :
                        mode === 'alone' ? cl.mapAlone :
                            mode === 'heatMap' ? cl.heatMap :
                                mode === 'settingsMark' ? cl.settingsMark :
                                    mode === 'settingsPerimetr' ? cl.settingsPerimetr : ''))
                : ''}>

            {(!loadingTelemetry || !loadingHeatMap || !loadingTelemetryAll) &&
                <>
                    {(mode === 'multi' && !loadingTelemetryAll) &&
                        <div className={cl.headerMapFlex}>
                            <div className={cl.headerSearchAnimalPageFlex}>
                                <Input
                                    allowClear
                                    type="text"
                                    placeholder={t("findAnimal")}
                                    className={cl.inputSearch}
                                    onChange={(e) => setValueSearch(e.target.value)}
                                    prefix={<SearchOutlined />} />
                            </div>
                            <div className={cl.countAnimalFlex}>
                                {t('countAnimals')} <span ><b className={cl.countAnimal}> {telemtryAllAnimal?.length}</b></span>
                                <label className={cl.telemetryToggle}>
                                    {/* <input type="checkbox" onChange={onTelemetryToggle} value='' />
                <span className="span-Tlm">TLM</span> */}
                                    <Switch checked={isTooltip} onChange={onTelemetryToggle} checkedChildren={t("aliasToogle")} unCheckedChildren={t("aliasToogle")} />
                                </label>
                                <label className={cl.telemetryToggle}>
                                    {/* <input type="checkbox" onChange={onTelemetryToggle} value='' />
                <span className="span-Tlm">TLM</span> */}
                                    <Switch checked={isMark} onChange={onMarksToggle} checkedChildren={t("marks")} unCheckedChildren={t("marks")} />
                                </label>
                            </div>
                        </div>
                    }
                    {(mode === 'alone') &&
                        <div className={cl.flexGroupRangeHeat}>
                            <div>
                                <RangeTimeChange animalId={animalId} fetchTelemetryTimeRange={fetchTelemetryTimeRange} />
                            </div>
                            {!loadingTelemetry && telemetry.length > 0 &&
                                <div className={cl.flexToogleGroup}>
                                    <div className={cl.heatmapToggle}>
                                        <Switch className={cl.toogleAntd} checked={isSingle} onChange={onSoloMultiToggle} checkedChildren={t("treck")} unCheckedChildren={t("treck")} disabled={isStatMode} />
                                    </div>
                                    <div className={cl.treckToggle}>
                                        <Switch checked={isHeatMap} onChange={onHeatMapToggle} checkedChildren={t("heatMap")} unCheckedChildren={t("heatMap")} disabled={isStatMode} />
                                    </div>
                                    <div className={cl.treckToggle}>
                                        <Switch checked={isStatMode} onChange={onStatisticToggle} checkedChildren={"℃"} unCheckedChildren={"℃"} />
                                    </div>
                                </div>
                            }
                        </div>
                    }
                    {(mode === 'heatMap') &&
                        <div className={cl.headerSelectRangeHeatMapAnimalPageFlex}>
                            <Select placeholder={t('selectRangeHeatMap')} className={cl.selectRangeHeatMap} defaultValue="7"
                                onChange={handleChange}
                                options={[
                                    { value: '7', label: t('sevenDays') },
                                    { value: '30', label: t('thirtyDays') },
                                    { value: '90', label: t('ninetyDays') },
                                ]} />
                            <Button type="primary" htmlType="submit" className={cl.btnSubmitHeatData} onClick={confirmUploadHeatMapData} disabled={isSpinner}>
                                {t('done')}
                            </Button>
                            {!loadingHeatMap &&
                                <Switch className={cl.markCheckHeatMap} checked={isMark} onChange={onMarksToggle} checkedChildren={t("marks")} unCheckedChildren={t("marks")} />
                            }
                        </div>
                    }

                    {(loadingTelemetry || loadingHeatMap || loadingTelemetryAll) &&
                        <center className={cl.spinnerPlace}>
                            <h2 className={cl.spinnerText}>{t("loadData")}</h2>
                            <div className={cl.spinner}></div>
                        </center>
                    }
                    {
                        (((!loadingTelemetryAll && telemtryAllAnimal.length > 0 && mode === 'multi')
                            || (!loadingHeatMap && geoLayerHeatMapData.length > 0 && mode === 'heatMap')
                            || (!loadingTelemetry && telemetry.length > 0 && mode === 'alone')) || (mode === 'settingsMark' || mode === 'settingsPerimetr')) &&
                        <>
                            {!isStatMode &&
                                <MapContainer
                                    center={[50, 70]}
                                    zoom={mode === 'alone' ? 16 : 8}
                                    minZoom={3}
                                    maxZoom={17}
                                    zoomControl={false}
                                    style={{ height: height, width: '100%', zIndex: 1 }}
                                    className={cl.map + ' ' + (mode === 'alone' ? cl.mapAlone : '')}
                                    maxBounds={innerBounds}
                                >
                                    <Layers />
                                    <FullscreenControl />
                                    <PolylineMeasurer
                                        position="topleft"
                                        translation={t}
                                        clearMeasurementsOnStop={false}
                                    />
                                    <ZoomControl position='topright' />
                                    {perimetrData &&
                                        <Perimetr perimetrData={perimetrData} />
                                    }
                                    {isMark &&
                                        <MarkersMarkLayer marks={marksData} />
                                    }
                                    {centerMap.latitude && centerMap.longitude && mode !== 'settingsMark' && mode !== 'settingsPerimetr' &&
                                        <CenterMap center={[centerMap.latitude, centerMap.longitude]} />
                                    }
                                    {mode === 'multi' &&
                                        <>
                                            <MarkersAllAnimal animals={filtredTelemetryData} mode={isTooltip} groupCluster={isGroup} />
                                            {filtredTelemetryData.length > 0 && filtredTelemetryData[0].lastLatitude && filtredTelemetryData[0].lastLongitude &&
                                                <CenterMap center={[
                                                    filtredTelemetryData[0].lastLatitude,
                                                    filtredTelemetryData[0].lastLongitude
                                                ]} zoom={10} />
                                            }
                                        </>
                                    }
                                    {mode === 'heatMap' &&
                                        <HeatMapLayer geoLayerData={geoLayerHeatMapData} loadMode={false} />
                                    }
                                    {mode === 'alone' &&
                                        <>
                                            <MarkersAnimalLayer animal={telemetry} mode={isSingle} />

                                            {isSingle === true &&
                                                <PolylineMapLayer positions={geoLayer} />
                                            }
                                            {(isHeatMap && geoLayer.length > 0) &&
                                                <HeatMapLayer geoLayerData={geoLayer} loadMode={true} />
                                            }
                                        </>
                                    }
                                    {mode === 'settingsMark' &&
                                        <>
                                            <LocationMarker onCreateLocationMark={(markLoc) => { setMarkLoc(markLoc) }} />
                                            <Marker position={markLoc}>
                                                <Popup>Ваша метка здесь! <div>Коорд: lat- {markLoc.lat?.toFixed(4)} && lon - {markLoc.lng?.toFixed(4)}</div></Popup>
                                            </Marker>

                                        </>
                                    }
                                    {
                                        mode === 'settingsPerimetr' &&
                                        <>
                                            <LocationMarkerPerimetr onCreateLocationMark={(perimetrMarkLoc) => { setPerimetrMarkLoc(perimetrMarkLoc) }} />

                                            <PerimetrAdd perimetrDataAdd={perimetrDataAdd} colorPerimetr={colorPerimetr} />

                                        </>
                                    }
                                </MapContainer>
                            }
                        </>
                    }
                </>
            }
            {
                (isStatMode) &&
                <ChartsComponent height={height} />
            }
            {
                ((!loadingTelemetryAll && telemtryAllAnimal.length === 0 && mode === 'multi')
                    || (!loadingHeatMap && geoLayerHeatMapData.length === 0 && mode === 'heatMap')
                    || (!loadingTelemetry && telemetry.length === 0 && mode === 'alone')) &&
                <center className={cl.noMapData}>
                    <h2 className={cl.spinnerText}>{t("noData")}</h2>
                </center>
            }
        </div>

    )
}

export default Map;