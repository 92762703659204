/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { FeatureGroup } from 'react-leaflet';
import L from 'leaflet';
import { HeatmapLayer } from 'react-leaflet-heatmap-layer-v3';
export const HeatMapLayer = ({ geoLayerData, loadMode }) => {

    const [telemetryData, settelemetryData] = useState(geoLayerData);
    const [isData, setIsData] = useState(true);
    useEffect(() => {
        if(geoLayerData.length > 0) {
            settelemetryData(geoLayerData)
        }
        setIsData(isData === true ? false : true)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [geoLayerData]);

    return (
        <FeatureGroup>
            {loadMode &&
                <HeatmapLayer
                    fitBoundsOnLoad={true}
                    fitBoundsOnUpdate={true}
                    points={telemetryData}
                    max={10}
                    radius={15}
                    minOpacity={0.5}
                    opacity={1}
                    blur={15}
                    longitudeExtractor={m => m[1]}
                    latitudeExtractor={m => m[0]}
                    intensityExtractor={m => 100}
                />
            }
            {!loadMode &&
                <HeatmapLayer
                fitBoundsOnLoad={true}
                fitBoundsOnUpdate={true}
                points={telemetryData}
                max={10}
                radius={15}
                minOpacity={0.5}
                opacity={1}
                blur={15}
                longitudeExtractor={m => m[1]}
                latitudeExtractor={m => m[0]}
                intensityExtractor={m => 100}
                />
            }
        </FeatureGroup>
    )
}


