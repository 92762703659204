import React, { useEffect, useState } from 'react';
import { Radio } from 'antd';
import { Button, Modal, Tooltip, List, Avatar, App } from 'antd';
import type { RadioChangeEvent } from 'antd';
import cl from './SettingsPage.module.css';
import clSpinner from '../../components/LoginForm/LoginForm.module.css';
import logo from '../../assets/static/djailayLogo.svg';
import { CreatePerimetrSetting } from './SettingsComponents/CreatePerimetr/CreatePerimetrSetting';
import { MarkCreate } from '../AnimalPage/animalPageComponents/MarkCreate/MarkCreate';
import { useAnimalActions, useMarkActions, usePerimetrActions } from '../../hooks/useAction';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import { AnimalCreate } from '../AnimalPage/animalPageComponents/AnimalCreate/AnimalCreate';
import { DeleteFilled } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { IAnimal } from '../../types/animal';
import { IMark } from '../../types/mark';
import { IPerimetr } from '../../types/perimetr';

const { confirm, info } = Modal;

export const SettingsPage = () => {
    const { t } = useTranslation();
    const [value, setValue] = useState('animal');
    const { animals, loadingAnimals, loadingAddDeleteAnimal } = useTypedSelector(state => state.animal);
    const { fetchAnimals, deleteAnimal } = useAnimalActions();
    const { addMark } = useMarkActions();
    const { authResponse } = useTypedSelector(state => state.auth);
    const { uploadFile, addAnimal } = useAnimalActions();
    const { marks, loadingMarks } = useTypedSelector(state => state.marks);
    const { fetchMarks, deleteMark } = useMarkActions();
    const { perimetrs, loadingPerimetr } = useTypedSelector(state => state.perimetr);
    const { fetchPerimetr, deletePerimetr } = usePerimetrActions();
    const [role] = useState(authResponse.user.role);
    const [isSpinner, setIsSpinner] = useState(loadingAnimals);
    const userId = authResponse.user.id;
    const options = [
        { label: t('animals'), value: 'animal' },
        { label: t('marks'), value: 'mark' },
        { label: t('perimetr'), value: 'perimetr' },
    ];
    const onChange = ({ target: { value } }: RadioChangeEvent) => {
        setValue(value);
    };
    const onDelete = async (animalId: string | null) => {
        if (animalId !== null) {
            deleteAnimal(animalId);
        };
    };
    const onDeleteMark = async (markId: string | null) => {
        if (markId !== null) {
            deleteMark(markId);
        };
    };
    const onDeletePerimetr = async (perimetrId: string | null) => {
        if (perimetrId !== null) {
            deletePerimetr(perimetrId);
        };
    };

    const confirmDelete = (animal: IAnimal) => {
        confirm({
            title: t('deleteAnimal?'),
            icon: <DeleteFilled />,
            content: t('animal') + ' ' + animal.name + t('beDelete'),
            okText: 'Ок',
            cancelText: t('cancel'),
            onOk() {
                onDelete(animal._id)
            },
            onCancel() { },
        });
    }
    const confirmMarkDelete = (mark: IMark) => {
        confirm({
            title: t('deleteMark?'),
            icon: <DeleteFilled />,
            content: t('mark') + ' ' + mark.name + ' ' + t('beDeleteS'),
            okText: 'Ок',
            cancelText: t('cancel'),
            onOk() {
                onDeleteMark(mark._id)
            },
            onCancel() { },
        });
    }
    const confirmPerimetrDelete = (perimetr: IPerimetr) => {
        confirm({
            title: t('deletePerimetr?'),
            icon: <DeleteFilled />,
            content: t('perimetr') + ' ' + perimetr.name + ' ' + t('beDeleteH'),
            okText: 'Ок',
            cancelText: t('cancel'),
            onOk() {
                onDeletePerimetr(perimetr._id)
            },
            onCancel() { },
        });
    }
    useEffect(() => {
        setIsSpinner(loadingAnimals)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingAnimals]);
    useEffect(() => {
        fetchMarks(userId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userId]);

    useEffect(() => {
        fetchMarks(userId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingMarks]);

    useEffect(() => {
        fetchPerimetr(userId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userId]);
    useEffect(() => {
        fetchPerimetr(userId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingPerimetr]);

    useEffect(() => {
        fetchAnimals(userId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userId]);
    useEffect(() => {
        fetchAnimals(userId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingAddDeleteAnimal]);
    return (
        <div className={cl.settingsContainer}>
            <Radio.Group options={options} onChange={onChange} value={value} optionType="button" className={cl.radioGroupBtn} />
            {value === 'animal' &&
                <div className={cl.settingsContainerAnimal}>
                    <div className={cl.itemListContainer}>
                        {!isSpinner &&
                            <List
                                size="small"
                                header={<div className={cl.headerAnimalList}>{t("animals")}</div>}
                                bordered={true}
                                className={cl.itemList}
                                itemLayout="horizontal"
                                dataSource={animals}
                                locale={{ emptyText: t("noData") }}
                                renderItem={(item, index) => (
                                    <div className={cl.listItemFlex}>
                                        <List.Item
                                            className={cl.listAnimalItem}
                                            actions={[<Button key="list-loadmore-edit" onClick={() => { confirmDelete(item) }} shape="circle" icon={<DeleteFilled />} danger className={cl.btnDelete} disabled={role === 'guest'}>
                                            </Button>]}>
                                            <List.Item.Meta
                                                avatar={<Avatar src={item.photoAnimal} />}
                                                title={<Button type="text" className={cl.titleAnimal} onClick={() => { }} >{item.name}</Button>}
                                            />
                                        </List.Item>
                                    </div>
                                )}
                            />
                        }
                        {isSpinner &&
                            <div className={clSpinner.spinnerPlace}>
                                <div> <img src={logo} alt="" className={clSpinner.imgLogo} /></div>
                                <div className={clSpinner.spinner}></div>
                                <div className={clSpinner.spinnerText}>{t("load")}</div>
                            </div>
                        }
                    </div>

                    <div className={cl.itemCardCreate}>
                        <AnimalCreate userId={userId} addAnimal={addAnimal} editAnimal={null} uploadFile={uploadFile} role={role} offWindow={null} isAnimalSelected={null} animalSelected={null} />
                    </div>
                </div>

            }
            {value === 'mark' &&
                <div className={cl.settingsContainerAnimal}>
                    <div className={cl.itemListContainer}>
                        {!isSpinner &&
                            <List
                                size="small"
                                header={<div className={cl.headerAnimalList}>{t("marks")}</div>}
                                bordered={true}
                                className={cl.itemList}
                                itemLayout="horizontal"
                                dataSource={marks}
                                locale={{ emptyText: 'Нет данных' }}
                                renderItem={(item, index) => (
                                    <div className={cl.listItemFlex}>
                                        <List.Item
                                            className={cl.listAnimalItem}
                                            actions={[<Button key="list-loadmore-edit" onClick={() => { confirmMarkDelete(item) }} shape="circle" icon={<DeleteFilled />} danger className={cl.btnDelete} disabled={role === 'guest'}>
                                            </Button>]}>
                                            <List.Item.Meta
                                                avatar={<Avatar src={item.picture} />}
                                                title={<Button type="text" className={cl.titleAnimal} onClick={() => { }} >{item.name}</Button>}
                                            />
                                        </List.Item>
                                    </div>
                                )}
                            />
                        }
                        {isSpinner &&
                            <div className={clSpinner.spinnerPlace}>
                                <div> <img src={logo} alt="" className={clSpinner.imgLogo} /></div>
                                <div className={clSpinner.spinner}></div>
                                <div className={clSpinner.spinnerText}>{t("load")}</div>
                            </div>
                        }
                    </div>

                    <div className={cl.itemMarkCreate}>
                        <MarkCreate userId={userId} role={role} addMark={addMark} offWindow={null}></MarkCreate>
                    </div>
                </div>
            }
            {value === 'perimetr' &&
                //  <div className={cl.textHeaderAddPerimetr}>{t("inDevelop")}</div>
                <div className={cl.settingsContainerAnimal}>
                    <div className={cl.itemListContainer}>
                        {!isSpinner &&
                            <List
                                size="small"
                                header={<div className={cl.headerAnimalList}>{t("perimetr")}</div>}
                                bordered={true}
                                className={cl.itemList}
                                itemLayout="horizontal"
                                dataSource={perimetrs}
                                locale={{ emptyText: 'Нет данных' }}
                                renderItem={(item, index) => (
                                    <div className={cl.listItemFlex}>
                                        <List.Item
                                            className={cl.listAnimalItem}
                                            actions={[<Button key="list-loadmore-edit" onClick={() => { confirmPerimetrDelete(item) }} shape="circle" icon={<DeleteFilled />} danger className={cl.btnDelete} disabled={role === 'guest'}>
                                            </Button>]}>
                                            <List.Item.Meta

                                                title={<Button type="text" className={cl.titleAnimal} onClick={() => { }} >{item.name}</Button>}
                                            />
                                        </List.Item>
                                    </div>
                                )}
                            />
                        }
                        {isSpinner &&
                            <div className={clSpinner.spinnerPlace}>
                                <div> <img src={logo} alt="" className={clSpinner.imgLogo} /></div>
                                <div className={clSpinner.spinner}></div>
                                <div className={clSpinner.spinnerText}>{t("load")}</div>
                            </div>
                        }
                    </div>

                    <div className={cl.itemPerimetrCreate}>
                        <CreatePerimetrSetting></CreatePerimetrSetting>
                    </div>
                </div>
            }
        </div>
    )
}