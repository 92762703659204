import React, { useEffect, useState } from 'react';
import cl from './AnimalTask.module.css';

import { Form, Button, Input, Tooltip, Modal } from 'antd';
import { DeleteFilled } from '@ant-design/icons';
import TextArea from 'antd/lib/input/TextArea';
import { useTranslation } from 'react-i18next';
import { useTypedSelector } from '../../../../../../hooks/useTypedSelector';
import { useTaskAnimalActions } from '../../../../../../hooks/useAction';
import { ITaskAnimal } from '../../../../../../types/taskAnimal';
const { confirm } = Modal;
export const AnimalTask = ({animalId, isEdit}) => {
    const { tasks } = useTypedSelector(state => state.taskAnimal);
    const { authResponse } = useTypedSelector(state => state.auth);
    const { t } = useTranslation();
    const { addTaskAnimal, fetchTaskAnimals, deleteTaskAnimal } = useTaskAnimalActions();
    const [isTaskAnimalDelete, setIsTaskAnimalDelete] = useState<boolean>(false);
    const [isSettigs, setIsSettigs] = useState(false);
    const [form] = Form.useForm();
    const [role] = useState(authResponse.user.role);
    const confirmDelete = (task: ITaskAnimal) => {
        confirm({
            title: t('deleteMark?'),
            icon: <DeleteFilled />,
            content: t('task')+ ' ' + task.task + ' ' + t('beDeleteS'),
            okText: 'Ок',
            cancelText: t('cancel'),
            onOk() {
                onDelete(task._id)
            },
            onCancel() { },
        });
    }
    const onCreateTask = (taskData: ITaskAnimal) => {
        addTaskAnimal(taskData, animalId);
        onReset()
    };

    const onDelete = async (animalId: string | null) => {
        if (animalId !== null) {
            deleteTaskAnimal(animalId);
            if (isTaskAnimalDelete === false) {
                setIsTaskAnimalDelete(true)
            } else {
                setIsTaskAnimalDelete(false)
            }
        };
    };
    const onReset = () => {
        form.resetFields();
    };
    useEffect(() => {

        setIsSettigs(isEdit)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isEdit]);


    useEffect(() => {
        if(animalId)
        fetchTaskAnimals(animalId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [animalId, isTaskAnimalDelete]);

    useEffect(() => {
        setIsSettigs(isEdit)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isEdit]);
    return (
        <div className={cl.tasksComponent}>
            <div className={cl.taskAnimalHeader}>{t("tasks")}</div>
            <Form
                form={form}
                name="validate_other"
                onFinish={onCreateTask}
            >
                <Form.Item
                    name="task" className={cl.textTask}>
                    <TextArea rows={3} placeholder={t("addTask")} className={cl.textAreaTask} />
                </Form.Item>
                <Form.Item>
                    <div className={cl.btnTaskContainer}>
                        <Button type="primary" htmlType="submit" className={cl.btnTask} >{t("addTask")}</Button>
                    </div>

                </Form.Item>
            </Form>
            {tasks &&
                <div className={cl.listTaskCont}>
                    {
                        tasks.map((h, index) => <li key={h._id} className={cl.listItem}>
                            <Tooltip placement="topLeft" overlay={<span><span style={{ color: '#81D000' }}>{t("text")}</span> {h.task}<br></br><span style={{ color: '#81D000' }}>{t("time")}</span> {h.timeCreate}</span>}>
                                <span className={cl.taskTextField}>{h.task.length < 30 ? h.task.substring(0, 30) : h.task.substring(0, 30) + '...'}</span>
                            </Tooltip>
                            <Tooltip placement="topLeft" title={t("deleteTask")}>
                                <Button onClick={() => { confirmDelete(h) }} shape="circle" icon={<DeleteFilled />} danger className={isSettigs ? cl.btnDelete : cl.hidden} disabled={role === 'guest'}>
                                </Button>
                            </Tooltip>
                        </li>)
                    }
                </div>
            }
        </div>
    )
}