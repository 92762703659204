import { ICommentSupportState, CommentSupportAction, CommentSupportActionType } from '../../types/commentSupport';

let initialState: ICommentSupportState = {
    commentsSupport: [],
    commentSupport: null,
    message: '',
    loading: false,
    error: null
};

export const commentSupportReducer = (state = initialState, action: CommentSupportAction): ICommentSupportState => {
    switch (action.type) {
        //добавить
        case CommentSupportActionType.ADD_COMMENT_SUPPORT:
            return { ...state, loading: true, error: null }
        //добавлено успешно
        case CommentSupportActionType.ADD_COMMENT_SUPPORT_SUCCESS:
            return { ...state, loading: false, error: null, commentsSupport: [action.payload, ...state.commentsSupport] }
        case CommentSupportActionType.FETCH_COMMENT_SUPPORT:
            return { ...state, loading: true, error: null }
        case CommentSupportActionType.FETCH_COMMENT_SUPPORT_SUCCESS:
            return { ...state, loading: false, error: null, commentsSupport: action.payload }
        case CommentSupportActionType.COMMENT_SUPPORT_ERROR:
            return { ...state, loading: false, error: action.payload }
        case CommentSupportActionType.DELETE_COMMENT_SUPPORT:
            return { ...state, loading: true }
        case CommentSupportActionType.DELETE_COMMENT_SUPPORT_SUCCESS:
            return { ...state, loading: false, error: action.payload, message: action.message }
        default:
            return state;
    }
}