import { EditFilled } from "@ant-design/icons";
import { Button, message, Tooltip } from "antd";
import confirm from "antd/lib/modal/confirm";
import React, { FC, useEffect, useState } from "react";
import $api from "../../api/axiosConfig";
import ModalCard from "../../common/ModalCard/ModalCard";
import { useUsersActions } from "../../hooks/useAction";
import { TiEdit } from "react-icons/ti";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { IUser } from "../../models/IUser";

import cl from "./UserPage.module.css";
import { RiLockPasswordLine } from "react-icons/ri";
import { ChangePassword } from "./UserPageComponent/ChangePassword";
import { useTranslation } from "react-i18next";
import { FaLayerGroup } from "react-icons/fa";


export const UserPage: FC = () => {
    const { t } = useTranslation();
    const { authResponse } = useTypedSelector(state => state.auth);
    const { user } = useTypedSelector(state => state.users);
    const { editModeAccessUser, editModeGroup } = useUsersActions();
    const [modalCard, setModalCard] = useState<boolean>(false);
    const [userData, setUserData] = useState<IUser>(authResponse.user);

    const [role] = useState(authResponse.user.role);
    useEffect(() => {
        $api.get('user/getUser/' + authResponse.user.id).then(res => {
            setUserData(res.data);
        });
    }, [user]);

    useEffect(() => {
        setUserData(user);
    }, [user]);

    const confirmChangeModeAccess = () => {
        confirm({
            title: t('changeModeAccess?'),
            icon: <EditFilled />,
            content: t('changeModeAccessCurrent') + ' ' + (userData.isModeAccess === true ? t('on') : t('off')),
            okText: 'Ок',
            cancelText: t('cancel'),
            onOk() {
                editModeAccessUser(authResponse.user.id);
                message.success(t('changeAccess'));
            },
            onCancel() { },
        });
    }
    const confirmChangeModeGroup = () => {
        confirm({
            title: t('changeModeGroup?'),
            icon: <EditFilled />,
            content: t('changeModeGroupCurrent') + ' ' + (userData.isModeGroup === true ? t('on') : t('off')),
            okText: 'Ок',
            cancelText: t('cancel'),
            onOk() {
                editModeGroup(authResponse.user.id);
                message.success(t('changeGroup'));
            },
            onCancel() { },
        });
    }
    return (
        <>
            {userData &&
                <div className={cl.userPageContainer}>
                    <ModalCard visible={modalCard} setVisible={setModalCard}>
                        <ChangePassword
                            offWindow={(modalCard: boolean) => { setModalCard(modalCard) }}
                            userId={authResponse.user.id} />
                    </ModalCard>
                    <div className={cl.itemFlexContainer}>
                        <div><span className={cl.userCardText}>{t('user')}</span></div>
                        <div>{userData.email}</div>
                    </div>
                    <div className={cl.itemFlexContainer}>
                        <div><span className={cl.userCardText}>{t('lastIn')}</span></div>
                        <div>{new Date(userData.lastSign).toLocaleString('kz-Kz', { hour12: false })}</div>
                    </div>
                    <div className={cl.itemFlexContainer}>
                        <div><span className={cl.userCardText}>{t('changeModeGroup')}</span></div>
                        <div><Tooltip placement="topLeft" title={t('changeModeGroup')} overlayClassName={cl.tooltipVisible}>
                            <Button onClick={() => confirmChangeModeGroup()} shape="circle" icon={<FaLayerGroup />} disabled={role === 'guest'} />

                        </Tooltip>
                         <span className={cl.userMode + ' ' + (userData.isModeGroup  ? cl.userModeGreen : cl.userModeRed)}>{userData.isModeGroup ? t('on') : t('off')}</span> 
                          
                        </div>

                    </div>
                    <div className={cl.itemFlexContainer}>
                        <div><span className={cl.userCardText}>{t('changeModeAccess')}</span></div>
                        <div><Tooltip placement="topLeft" title={t('changeModeAccess')} overlayClassName={cl.tooltipVisible}>
                            <Button onClick={() => confirmChangeModeAccess()} shape="circle" icon={<TiEdit />} disabled={role === 'guest'} />
                            <span className={cl.userMode + ' ' + (userData.isModeAccess  ? cl.userModeGreen : cl.userModeRed)}>{userData.isModeAccess ? t('on') : t('off')}</span> 
                        </Tooltip>
                        </div>
                    </div>
                    <div className={cl.itemFlexContainer}>
                        <div><span className={cl.userCardText}>{t('changeModePassword')}</span></div>
                        <div><Tooltip placement="topLeft" title={t('changeModePassword')} overlayClassName={cl.tooltipVisible}>
                            <Button onClick={() => { setModalCard(true) }} shape="circle" icon={<RiLockPasswordLine />} disabled={role === 'guest'} />
                        </Tooltip>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}
export default UserPage;
