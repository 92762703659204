
import { IAnimalState, AnimalAction, AnimalActionType } from '../../types/animal';

let initialState: IAnimalState = {
    animals: [],
    animal: {
        _id: null,
        name: '',
        device: '',
        photoAnimal: '',
        weight: 0,
        age: null,
        deviceType: ''
    },
    animalFotoUpload: null,
    message: '',
    loadingAnimals: false,
    loadingAddDeleteAnimal: false,
    commentSupport: true,
    error: null,
    messageCreateAnimal: null 
};

export const animalsReducer = (state = initialState, action: AnimalAction): IAnimalState => {
    switch (action.type) {
        //добавить
        case AnimalActionType.ADD_ANIMALS:
            return { ...state, loadingAddDeleteAnimal: true, error: null, animalFotoUpload: null, messageCreateAnimal: null }
        //добавлено успешно
        case AnimalActionType.ADD_ANIMALS_SUCCESS:
            return { ...state, loadingAddDeleteAnimal: false, error: null, messageCreateAnimal: action.message, animal: action.payload }
        //список лошадей
        case AnimalActionType.FETCH_ANIMALS:
            return { ...state, loadingAnimals: true, error: null }
        //получение успешно
        case AnimalActionType.ANIMALS_SUCCESS:
            return { ...state, loadingAnimals: false, error: null, animals: action.payload, animalFotoUpload: null }
        //одно животное
        case AnimalActionType.FETCH_ANIMAL:
            return { ...state, loadingAnimals: true, error: null, animalFotoUpload: null }
        //получение успешно одного животного
        case AnimalActionType.ANIMAL_SUCCESS:
            return { ...state, loadingAnimals: false, error: null, animal: action.payload }
        //если еррор
        case AnimalActionType.ANIMALS_ERROR:
            return { ...state, loadingAnimals: false, error: action.message, messageCreateAnimal: action.message }
        // удаление
        case AnimalActionType.DELETE_ANIMAL:
            return { ...state, loadingAddDeleteAnimal: true, loadingAnimals: true }
        // удаление успешно
        case AnimalActionType.DELETE_ANIMAL_SUCCESS:
            return { ...state, loadingAddDeleteAnimal: false, loadingAnimals: false, error: action.payload, message: action.message }
        // редактирование 
        case AnimalActionType.EDIT_ANIMAL:
            return { ...state, loadingAddDeleteAnimal: true, error: null, animalFotoUpload: null }
        // редактирование успешно
        case AnimalActionType.EDIT_ANIMAL_SUCCESS:
            return { ...state, loadingAddDeleteAnimal: false, error: null, message: action.message, animal: action.payload, animals: [...state.animals] }
        // редактирование 
        case AnimalActionType.ADD_ANIMAL_FOTO:
            return { ...state, error: null, animalFotoUpload: null}
        case AnimalActionType.ADD_ANIMAL_FOTO_SUCCESS:
            return { ...state, error: null, animalFotoUpload: action.payload }
        default:
            return state;
    }
}