import { Coords } from "./telemetry";

export enum AnimalActionType {
    ADD_ANIMALS = 'ADD_ANIMALS',
    ADD_ANIMALS_SUCCESS = 'ADD_ANIMALS_SUCCESS',
    ANIMALS_SUCCESS = 'ANIMALS_SUCCESS',
    ANIMALS_ERROR = 'ANIMALS_ERROR',
    FETCH_ANIMALS = 'FETCH_ANIMALS',
    FETCH_ANIMAL = 'FETCH_ANIMAL',
    ANIMAL_SUCCESS = 'ANIMAL_SUCCESS',
    DELETE_ANIMAL = 'DELETE_ANIMAL',
    DELETE_ANIMAL_SUCCESS = 'DELETE_ANIMAL_SUCCESS',
    EDIT_ANIMAL = 'EDIT_ANIMAL',
    EDIT_ANIMAL_SUCCESS = 'EDIT_ANIMAL_SUCCESS',
    ADD_ANIMAL_FOTO = 'ADD_ANIMAL_FOTO',
    ADD_ANIMAL_FOTO_SUCCESS = 'ADD_ANIMAL_FOTO_SUCCESS'
}

export interface IAnimal {
    _id: null | string;
    name: string;
    device: string;
    deviceType: null | string;
    photoAnimal: string;
    weight: null | number;
    age: null | Date | string | number;
    yBirthday?: any;
    mBirthday?: null | string | number;
    breed?: null | string;
    lastTelemetry?: null | string;
    lastLatitude?:  null | string | number,
    lastLongitude?:  null | string | number,
}

export interface IAnimalState {
    animals: IAnimal[];
    animal: IAnimal;
    loadingAnimals: boolean;
    loadingAddDeleteAnimal: boolean;
    error: null | string;
    message: null | string;
    commentSupport: null | boolean;
    animalFotoUpload: null| string;
    messageCreateAnimal: null | boolean;
}

type IAddAnimalAction = {
    type: AnimalActionType.ADD_ANIMALS;
}
type IAnimalSuccessAction = {
    type: AnimalActionType.ANIMALS_SUCCESS;
    payload: any;
    message: string;
}
type IAddAnimalSuccessAction = {
    type: AnimalActionType.ADD_ANIMALS_SUCCESS;
    payload: IAnimal;
    message: any;

}
type IAnimalErrorAction = {
    type: AnimalActionType.ANIMALS_ERROR;
    message: any;
}

type IFetchAnimalsAction = {
    type: AnimalActionType.FETCH_ANIMALS;
}

type IFetchAnimalAction = {
    type: AnimalActionType.FETCH_ANIMAL;
}
type IFetchAnimalSuccessAction = {
    type: AnimalActionType.ANIMAL_SUCCESS;
    payload: IAnimal;
}

type IDeleteAnimalAction = {
    type: AnimalActionType.DELETE_ANIMAL;
}

type IDeleteAnimalSuccessAction = {
    type: AnimalActionType.DELETE_ANIMAL_SUCCESS;
    payload: string;
    message: string;
}

type IEditAnimalAction = {
    type: AnimalActionType.EDIT_ANIMAL;
}

type IEditAnimalSuccessAction = {
    type: AnimalActionType.EDIT_ANIMAL_SUCCESS;
    payload: IAnimal;
    message: string;
}

type IAddAnimalFotoAction = {
    type: AnimalActionType.ADD_ANIMAL_FOTO;
    
}

type IAddAnimalFotoSuccessAction = {
    type: AnimalActionType.ADD_ANIMAL_FOTO_SUCCESS;
    payload: string;
 
}
export type AnimalAction = IAddAnimalAction | IAnimalSuccessAction |
    IAnimalErrorAction | IFetchAnimalsAction |
    IAddAnimalSuccessAction | IFetchAnimalAction |
    IFetchAnimalSuccessAction | IDeleteAnimalAction
    | IDeleteAnimalSuccessAction | IEditAnimalAction
    | IEditAnimalSuccessAction | IAddAnimalFotoAction | IAddAnimalFotoSuccessAction;