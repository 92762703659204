import { AxiosResponse } from "axios";
import $api from "../api/axiosConfig";
import { CommentSupportResponse } from '../models/response/CommentSupportResponse';
import { ICommentSupport } from "../types/commentSupport";

export default class CommentSupportService {
    static async addCommentSupport(comment: ICommentSupport, userId: string): Promise<AxiosResponse<CommentSupportResponse>> {
        return $api.post('commentSupport/createComment', { comment, userId });
    }
    static async fetchCommentsSupport(userId: string): Promise<AxiosResponse<CommentSupportResponse>> {
        return $api.get('commentSupport/getCommentsSupport/' + userId);
    }
    static async deleteCommentSupport(commentId: string): Promise<AxiosResponse<CommentSupportResponse>> {
        return $api.delete('commentSupport/delete/' + commentId);
    }
}

