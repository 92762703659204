import React, { FC } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Content } from 'antd/lib/layout/layout';
import { MainPage } from '../../MainPage/MainPage';
import { NotFoundPage } from '../../NotFoundPage/NotFoundPage';
import { AnimalPage } from '../../AnimalPage/AnimalPage';
import { SupportPage } from '../../SupportPage/SupportPage';
import cl from '../LayoutPage.module.css';
import UserPage from '../../UserPage/UserPage';
import { HeatMapPage } from '../../HeatMapPage/HeatMapPage';
import { SettingsPage } from '../../SettingsPage/SettingPage';
import AnimalPageBot from '../../AnimalPage/AnimalPageBot';

export const ContentLayout: FC = () => {
    return (
        <Content className={cl.contContainer} >
            <Switch>
                <Route path="/main" exact>
                    <MainPage />
                </Route>
                <Route path="/animal" exact>
                    <AnimalPage />
                </Route>
                <Route path="/animalBot/:userId/:animalName/:lat/:lon" exact>
                    <AnimalPageBot />
                </Route>
                <Route path="/user" exact>
                    <UserPage />
                </Route>
                <Route path="/support" exact>
                    <SupportPage />
                </Route>
                <Route path="/heatmap" exact>
                    <HeatMapPage />
                </Route>
                <Route path="/settings" exact>
                    <SettingsPage />
                </Route>
                <Route path="/notFound" exact>
                    <NotFoundPage />
                </Route>
                <Redirect to="/main" />
            </Switch>
        </Content>
    )
}