import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { MapContainer, ZoomControl, Marker, Popup, } from 'react-leaflet';
import { LatLngBoundsLiteral } from 'leaflet';
import { FullscreenControl } from "react-leaflet-fullscreen";
import { MarkersAnimalBot } from '../../common/Map/componentsMap/MarkerAnimalBot.component';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import { useMarkActions, usePerimetrActions } from '../../hooks/useAction';
import { MarkersMarkLayer } from '../../common/Map/componentsMap/MarkersMarkLayer';
import Perimetr from '../../common/Map/componentsMap/Perimetr';
import cl from '../../common/Map/Map.module.css';
import Layers from '../../common/Map/componentsMap/Layers';
import { useTranslation } from 'react-i18next';

interface RouteParams {
    userId: string;
    animalName: string;
    lat: string;
    lon: string;
}
const innerBounds: LatLngBoundsLiteral = [[10, 60], [70, 90]];
const AnimalPageBot: React.FC = () => {
    const { t } = useTranslation();
    const { userId, animalName, lat, lon } = useParams<RouteParams>();
    const { perimetrs } = useTypedSelector(state => state.perimetr);
    const { marks } = useTypedSelector(state => state.marks);
    const { fetchPerimetr } = usePerimetrActions();
    const { fetchMarks } = useMarkActions();
    // Преобразуйте параметры lat и lon в числовой тип, если это необходимо
    const latNumber = parseFloat(lat);
    const lonNumber = parseFloat(lon);
    const [centerMap, setCenterMap] = useState<{ latitude: number; longitude: number } | null>(
        { latitude: latNumber, longitude: lonNumber }
    );

    const [perimetrData, setPerimetrData] = useState([]);
    const [marksData, setMarksData] = useState([]);

    useEffect(() => {
        fetchMarks(userId)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        fetchPerimetr(userId)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        setMarksData(marks)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchMarks]);
    useEffect(() => {
        setPerimetrData(perimetrs)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchPerimetr]);
    return (
        <div >
            <MapContainer
                center={[latNumber, lonNumber]}
                zoom={8}
                minZoom={3}
                maxZoom={17}
                zoomControl={false}
                style={{ height: '92.5vh', width: '100%', zIndex: 1 }}
                className={cl.map}
                maxBounds={innerBounds}
            >
                <MarkersMarkLayer marks={marksData} />
                <MarkersAnimalBot animalBotName={animalName} animalBotCoords={centerMap}></MarkersAnimalBot>
                <Perimetr perimetrData={perimetrData} />
                <Layers />
                <FullscreenControl />
            </MapContainer>
        </div>
    )
};

export default AnimalPageBot;