/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useState } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';
import cl from '../LayoutPage.module.css';
import { useAuthActions } from '../../../hooks/useAction';
import { IoEarthOutline } from "react-icons/io5";
import { ImStatsDots, ImUser } from "react-icons/im";
import { RiFileListLine } from "react-icons/ri";
import { FcCustomerSupport, FcGallery } from "react-icons/fc";
import { useTranslation } from 'react-i18next';
import { AiFillSetting } from 'react-icons/ai';

const { Sider } = Layout;

export const SiderLayout: FC = () => {
    const router = useRouteMatch();
    const { logout } = useAuthActions();
    const [collapsed, setCollapsed] = useState(true);
    const [current, setCurrent] = useState();
    const { t } = useTranslation();
    const logoutHandler = async () => {
        try {
            logout();
        } catch (e) { }
    };
    const onCollapse = () => {
        setCollapsed(!collapsed)
    };
    const items = [
        // {
        //     label: <Link to="/stat" className={cl.linkMenu}>{t("statistic")}</Link >,
        //     key: 'Sider1',
        //     icon: <ImStatsDots />,
        //     className: cl.menuItem,
        // },
        {
            label: <Link to="/main" className={cl.linkMenu}>{t("map")} </Link >,
            key: 'Sider2',
            icon: <IoEarthOutline />,
            className: cl.menuItem,
        },
        {
            label: <Link to="/heatmap" className={cl.linkMenu} >{t("heatMap")}</Link >,
            key: 'Sider3',
            icon: <FcGallery />,
            className: cl.menuItem,

        },
        {
            label: <Link to="/animal" className={cl.linkMenu} >{t("cardAnimals")}</Link >,
            key: 'Sider4',
            icon: <RiFileListLine />,
            className: cl.menuItem,

        },
        {
            label: <Link to="/support" className={cl.linkMenu}>{t("textSupport")}</Link >,
            key: 'Sider5',
            icon: <FcCustomerSupport />,
            className: cl.menuItem,

        },
        {
            label: <Link to="/user" className={cl.linkMenu} >{t("cabinet")}</Link >,
            key: 'Sider6',
            icon: <ImUser />,
            className: cl.menuItem,

        },
        {
            label: <Link to="/settings" className={cl.linkMenu} >{t("settings")}</Link >,
            key: 'Sider7',
            icon: <AiFillSetting />,
            className: cl.menuItem,

        },
        {
            label: <a onClick={logoutHandler}>{t("exit")}</a>,
            key: 'Sider8',
            icon: <LogoutOutlined />,
            className: cl.menuItem,

        },
    ];
    return (
        <Sider collapsible collapsedWidth={0} collapsed={collapsed} onCollapse={onCollapse} width={250}   className={cl.sideMenu} >
            <Menu theme="dark" mode="vertical"  onClick={onCollapse} items={items} />
        </Sider>
    )
}

export default SiderLayout;