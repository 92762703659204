import { combineReducers } from "redux";
import { authReducer } from "./reducers/auth.reducer";
import { animalsReducer } from "./reducers/animals.reducer";
import { telemetryReducer } from "./reducers/telemetry.reducer";
import { usersReducer } from "./reducers/users.reducer";
import { taskAnimalsReducer } from "./reducers/taskAnimal.reducer";
import { commentSupportReducer } from "./reducers/commentSupport.reducer";
import { mapReducer } from "./reducers/map.reducer";
import { marksReducer } from "./reducers/mark.reducer";
import { perimetrsReducer } from "./reducers/perimetr.reducer";
import { heatMapTelemetryReducer } from "./reducers/heatMapTelemetry.reducer";

export const rootReducer = combineReducers({
    users: usersReducer,
    auth: authReducer,
    animal: animalsReducer,
    telemetry: telemetryReducer,
    taskAnimal: taskAnimalsReducer,
    commentSupport: commentSupportReducer,
    mapParam: mapReducer,
    marks: marksReducer,
    perimetr: perimetrsReducer,
    heatMapTelemetry: heatMapTelemetryReducer
});

export type RootState = ReturnType<typeof rootReducer>