/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import cl from './AnimaDetailsl.module.css';
import { AnimalCard } from './detailsAnimalComponents/AnimalCard/AnimalCard';
import { AnimalTask } from './detailsAnimalComponents/AnimalTasks/AnimalTask.component';
import Map from '../../../../common/Map/Map';



export const AnimalDetails = ({ animalSelected, isEdit, userId }) => {
    return (
        <>
            {animalSelected &&
                <div className={cl.detailAnimalPageFlex}>
                    <div className={cl.detailCardTaskAnimalPageFlex}>
                        <div className={cl.detailCardAnimalPageFlex}>
                            <AnimalCard isEdit={isEdit} animalSelected={animalSelected} userId={userId} />
                        </div>
                        <div className={cl.detailTaskAnimalPageFlex}>
                            <AnimalTask isEdit={isEdit} animalId={animalSelected._id} />
                        </div>
                    </div>
                    <div className={cl.detailMapAnimalPageFlex}>
                        <Map mode={'alone'} animalId={animalSelected._id} height='100%' onCreateLocationMark={null} colorPerimetr={null}
                            perimetrDataAdd={null} />
                    </div>
                </div>
            }

        </>
    );
}
