import { TaskAnimalActionType } from './../../types/taskAnimal';
import { Dispatch } from "redux"
import TaskAnimalService from '../../services/TaskAnimalService';
import { ITaskAnimal, TaskAnimalAction } from '../../types/taskAnimal';

export const addTaskAnimal = (task: ITaskAnimal, animalId: string) => {
    return async (dispatch: Dispatch<TaskAnimalAction>) => {
        try {
            dispatch({ type: TaskAnimalActionType.ADD_TASK_ANIMAL });
            const response = await TaskAnimalService.addTaskAnimal(task, animalId);
            dispatch({ type: TaskAnimalActionType.ADD_TASK_ANIMAL_SUCCESS, message: response.data.message, payload: response.data.task });
        } catch (error) {
            dispatch({ type: TaskAnimalActionType.TASK_ANIMAL_ERROR, payload: 'Произошла ошибка при создании животного!' })
        }
    }
}

export const fetchTaskAnimals = (animalId: string) => {
    return async (dispatch: Dispatch<TaskAnimalAction>) => {
        try {
            dispatch({ type: TaskAnimalActionType.FETCH_TASK_ANIMAL });
            const response = await TaskAnimalService.fetchTasksAnimals(animalId);
            dispatch({ type: TaskAnimalActionType.FETCH_ANIMAL_SUCCESS, message: response.data.message, payload: response.data.tasks });
        } catch (error) {
            dispatch({ type: TaskAnimalActionType.TASK_ANIMAL_ERROR, payload: 'Произошла ошибка при загрузке животных!' })
        }
    }
}

export const deleteTaskAnimal = (taskId: string) => {
    return async (dispatch: Dispatch<TaskAnimalAction>) => {
        try {
            dispatch({ type: TaskAnimalActionType.DELETE_TASK_ANIMAL });
           // console.log('deleteTaskAnimal dispatch: ', taskId)
            const response = await TaskAnimalService.deleteTaskAnimal(taskId);
            dispatch({ type: TaskAnimalActionType.DELETE_TASK_ANIMAL_SUCCESS, message: response.data.message, payload: response.data.message });
        } catch (error) {
            dispatch({ type: TaskAnimalActionType.TASK_ANIMAL_ERROR, payload: 'Произошла ошибка при загрузке животных!' })
        }
    }
}

