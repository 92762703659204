/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import cl from './AnimalPageFlex.module.css';
import { IAnimal } from '../../types/animal';
import { useTypedSelector } from '../../hooks/useTypedSelector';


import { Button, Tooltip } from 'antd';
import { Input, } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { GiGears } from "react-icons/gi";
import { useTranslation } from 'react-i18next';
import { AnimalList } from './animalPageComponents/AnimalList/AnimalList.component';
import { AnimalDetails } from './animalPageComponents/AnimalDetails/AnimalDetails.component';

export const AnimalPage = () => {
    const { authResponse } = useTypedSelector(state => state.auth);
    const { user } = useTypedSelector(state => state.users);
    const { t } = useTranslation();
    const userId = authResponse.user.id;
    const { animal } = useTypedSelector(state => state.animal);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars

    const [animalSelected, setAnimalSelected] = useState<IAnimal>(null);
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [isModeAccess, setIsModeAccess] = useState<boolean>(authResponse.user.isModeAccess);

    const [valueSearch, setValueSearch] = useState('');

    useEffect(() => {
        if (user.isModeAccess !== null)
            setIsModeAccess(user.isModeAccess)
    }, [user.isModeAccess]);

    return (
        <>
            {authResponse.user &&
                <div className={cl.animalPageFlex}>
                    <div className={cl.headerAnimalPageFlex}>
                        <div className={cl.headerSearchAnimalPageFlex}>
                            <Input
                                allowClear
                                type="text"
                                placeholder={t("findAnimal")}
                                className={cl.inputSearch}
                                onChange={(e) => setValueSearch(e.target.value)}
                                prefix={<SearchOutlined />} />
                        </div>
                        <div className={cl.headerEditAnimalPageFlex}>
                            <Tooltip placement="topLeft" title={t("modeEdit")}>
                                <Button
                                    className={isModeAccess ? cl.btnEditAnimal : cl.hidden}
                                    onClick={() => { setIsEdit(isEdit === true ? false : true) }}
                                    shape="circle"
                                    icon={<GiGears />} />
                            </Tooltip>
                        </div>
                    </div>
                    <div className={cl.bodyAnimalPageFlex}>
                        <div className={cl.listAnimalPageFlex}>
                            <AnimalList
                                userId={userId}
                                valueSearch={valueSearch}
                                onAnimalSelected={(selectAnimal: IAnimal) => { setAnimalSelected(selectAnimal) }}
                                isEdit={isEdit}
                            />
                        </div>
                        <div className={cl.detailsAnimalPageFlex}>
                            {animalSelected &&
                                <AnimalDetails
                                    animalSelected={animalSelected}
                                    isEdit={isEdit}
                                    userId={userId} />
                            }
                        </div>

                    </div>
                </div>
            }
        </>
    )
}