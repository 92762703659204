export enum CommentSupportActionType {
    ADD_COMMENT_SUPPORT = 'ADD_COMMENT_SUPPORT',
    ADD_COMMENT_SUPPORT_SUCCESS = 'ADD_COMMENT_SUPPORT_SUCCESS',
    COMMENT_SUPPORT_ERROR = 'COMMENT_SUPPORT_ERROR',
    FETCH_COMMENT_SUPPORT = 'FETCH_COMMENT_SUPPORT',
    FETCH_COMMENT_SUPPORT_SUCCESS = 'FETCH_COMMENT_SUPPORT_SUCCESS',
    DELETE_COMMENT_SUPPORT = 'DELETE_COMMENT_SUPPORT',
    DELETE_COMMENT_SUPPORT_SUCCESS = 'DELETE_COMMENT_SUPPORT_SUCCESS',
    EDIT_COMMENT_SUPPORT = 'EDIT_COMMENT_SUPPORT',
    EDIT_COMMENT_SUPPORT_SUCCESS = 'EDIT_COMMENT_SUPPORT_SUCCESS'
}

export interface ICommentSupport {
    _id: null | string;
    comment: null | string;
    timeStamp: null|string;
    status: null| string;
}

export interface ICommentSupportState {
    commentsSupport: ICommentSupport[];
    commentSupport: ICommentSupport | null;
    loading: boolean;
    error: null | string;
    message: null | string;
}

type IAddCommentSupporAction = {
    type: CommentSupportActionType.ADD_COMMENT_SUPPORT;

}
type IAddCommentSupportSuccessAction = {
    type: CommentSupportActionType.ADD_COMMENT_SUPPORT_SUCCESS;
    payload: ICommentSupport;
}

type ICommentSupportErrorAction = {
    type: CommentSupportActionType.COMMENT_SUPPORT_ERROR;
    payload: string;
}

type IFetchCommentSupportAction = {
    type: CommentSupportActionType.FETCH_COMMENT_SUPPORT;
}

type IFetchCommentSupportSuccessAction = {
    type: CommentSupportActionType.FETCH_COMMENT_SUPPORT_SUCCESS;
    payload: ICommentSupport[];
}


type IDeleteCommentSupportAction = {
    type: CommentSupportActionType.DELETE_COMMENT_SUPPORT;
}

type IDeleteCommentSupportSuccessAction = {
    type: CommentSupportActionType.DELETE_COMMENT_SUPPORT_SUCCESS;
    payload: string;
    message: string;
}

export type CommentSupportAction = IAddCommentSupporAction | IAddCommentSupportSuccessAction | ICommentSupportErrorAction | IFetchCommentSupportAction | IFetchCommentSupportSuccessAction | IDeleteCommentSupportAction | IDeleteCommentSupportSuccessAction;