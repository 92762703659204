import { IPerimetr } from './../types/perimetr';

import { AxiosResponse } from "axios";
import $api from "../api/axiosConfig";
import { PerimetrResponse } from '../models/response/PerimetrResponse';

export default class PerimetrService {
    static async fetchPerimetrs(userId: string): Promise<AxiosResponse<PerimetrResponse>> {
        return $api.get('perimetr/getPerimetr/' + userId);
    }

    static async addPerimetr(perimetr: IPerimetr, userId: string): Promise<AxiosResponse<PerimetrResponse>> {
    
        const createPerimetr = {
            perimetr: perimetr,
            userId: userId
        }
        return $api.post('perimetr/create', createPerimetr);
    }

    static async deletePerimetr(perimetrId: string): Promise<AxiosResponse<PerimetrResponse>> {
      
        return $api.delete('perimetr/delete/' + perimetrId);
    }
}

