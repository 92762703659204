import { TaskAnimalActionType } from './../../types/taskAnimal';
import { ITaskAnimalState, TaskAnimalAction } from '../../types/taskAnimal';

let initialState: ITaskAnimalState = {
    tasks: [],
    task: null,
    message: '',
    loading: false,
    error: null
};

export const taskAnimalsReducer = (state = initialState, action: TaskAnimalAction): ITaskAnimalState => {
    switch (action.type) {
        //добавить
        case TaskAnimalActionType.ADD_TASK_ANIMAL:
            return { ...state, loading: true, error: null }
        //добавлено успешно
        case TaskAnimalActionType.ADD_TASK_ANIMAL_SUCCESS:
            return { ...state, loading: false, error: null, tasks: [action.payload, ...state.tasks] }
        case TaskAnimalActionType.FETCH_TASK_ANIMAL:
            return { ...state, loading: true, error: null }
        case TaskAnimalActionType.FETCH_ANIMAL_SUCCESS:
            return { ...state, loading: false, error: null, tasks: action.payload }
        case TaskAnimalActionType.TASK_ANIMAL_ERROR:
            return { ...state, loading: false, error: action.payload }
        case TaskAnimalActionType.DELETE_TASK_ANIMAL:
            return { ...state, loading: true, }
        case TaskAnimalActionType.DELETE_TASK_ANIMAL_SUCCESS:
            return { ...state, loading: false, error: action.payload, message: action.message }
        default:
            return state;
    }
}