import { AuthResponse } from "../models/response/AuthResponse";
export enum AuthActionType {
    CHECK_AUTH = 'CHECK_AUTH',
    CHECK_AUTH_SUCCESS = 'CHECK_AUTH_SUCCESS',
    CHECK_AUTH_ERROR = 'CHECK_AUTH_ERROR',
    LOGIN = 'LOGIN',
    LOGOUT = 'LOGOUT'

}
export interface IAuthState {
    authResponse: AuthResponse,
    isAuth: boolean;
    loading:  null | boolean;
    isModeAccess: boolean;
    isModeGroup: boolean;
    error: null | string;
}

interface ICheckAuthAction {
    type: AuthActionType.CHECK_AUTH;
    loading: null| boolean;
}
interface ICheckAuthSuccessAction {
    type: AuthActionType.CHECK_AUTH_SUCCESS;
    payload: AuthResponse
}
interface ICheckAuthErrorAction {
    type: AuthActionType.CHECK_AUTH_ERROR;
    payload?: null | string;
}

interface ILoginAction {
    type: AuthActionType.LOGIN;
    payload?:  any;
}

interface ILogoutAction {
    type: AuthActionType.LOGOUT;
}

export type AuthAction = ICheckAuthAction | ICheckAuthSuccessAction | ICheckAuthErrorAction | ILoginAction | ILogoutAction;