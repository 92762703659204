/* eslint-disable no-lone-blocks */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect, useState } from 'react';
import { useComentSupportActions } from '../../hooks/useAction';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import { ICommentSupport } from '../../types/commentSupport';
import { CaretRightOutlined, DeleteFilled } from '@ant-design/icons';
import { Divider, Modal, Tooltip, Collapse, Image } from 'antd';
import cl from './SupportPage.module.css';
import { useTranslation } from 'react-i18next';

import main1 from "../../assets/static/supportPage/main1.png";
import main2 from "../../assets/static/supportPage/main2.png";
import main3 from "../../assets/static/supportPage/main3.png";
import main4 from "../../assets/static/supportPage/main4.png";
import main5 from "../../assets/static/supportPage/main5.png";
import sup1 from "../../assets/static/supportPage/sup1.png";
import sup2 from "../../assets/static/supportPage/sup2.png";
import sup3 from "../../assets/static/supportPage/sup3.png";
import sup4 from "../../assets/static/supportPage/sup4.png";
import sup5 from "../../assets/static/supportPage/sup5.png";
import sup6 from "../../assets/static/supportPage/sup6.png";
import sup7 from "../../assets/static/supportPage/sup7.png";
import sup8 from "../../assets/static/supportPage/sup8.png";
import sup9 from "../../assets/static/supportPage/sup9.png";
import sup10 from "../../assets/static/supportPage/sup10.png";
const { confirm } = Modal;
const { Panel } = Collapse;

export const SupportPage: FC = () => {
    const { commentsSupport } = useTypedSelector(state => state.commentSupport);
    const { addCommentSupport, fetchCommentSupport, deleteCommentSupport } = useComentSupportActions();
    const { authResponse } = useTypedSelector(state => state.auth);
    const { t } = useTranslation();
    const userId = authResponse.user.id;
    const [isCommentSupportDelete, setIsCommentSupportDelete] = useState<boolean>(false);

    const onCommentSupportCreate = (commentSupport: ICommentSupport) => {
        addCommentSupport(commentSupport, userId);
    };

    const onDelete = async (commentSupportId: string | null) => {
        if (commentSupportId !== null) {
            deleteCommentSupport(commentSupportId);
            if (isCommentSupportDelete === false) {
                setIsCommentSupportDelete(true)
            } else {
                setIsCommentSupportDelete(false)
            }
        };
    };

    useEffect(() => {
        fetchCommentSupport(userId);
        //  setMess(message)
    }, [isCommentSupportDelete]);

    return (
        <>
            <div className={cl.headerStat}>
                <h3>{t('textSupport')}</h3>
            </div>

            <Collapse
                accordion={true}
                expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} className={cl.panelItemTextHeader} />}>
                <Panel header={<span className={cl.panelItemTextHeader}>{t("textSupport1")}</span>} key="PanelSupport1" className={cl.panelStat}>
                    <div className={cl.panel1Inst}>
                        <div >
                            <div className={cl.panelItemText}>
                                <div>{t("textSupport2")}</div>
                                <div>{t("textSupport3")}</div>
                            </div>
                            <div className={cl.panelItemFile}>
                                <Image src={main1} />
                            </div>
                            <Divider />
                            <div className={cl.panelItemText}>{t("textSupport4")}</div>
                            <div className={cl.panelItemFile}>
                                <Image src={main2} />
                            </div>
                            <Divider />
                            <div className={cl.panelItemText}>
                                <div>{t("textSupport5")}</div>
                                <div>{t("textSupport6")}</div>
                            </div>
                            <div className={cl.panelItemFile}>
                                <Image src={main3} />
                            </div>
                            <Divider />
                            <div className={cl.panelItemText}>
                                <div>{t("textSupport7")}</div>
                                <div>{t("textSupport8")}</div>
                            </div>
                            <div className={cl.panelItemFile}>
                                <Image src={main4} />
                            </div>
                            <Divider />
                            <div className={cl.panelItemText}>
                                <div>{t("textSupport9")}</div>
                                <div>{t("textSupport10")}</div>
                                <div>{t("textSupport11")}</div>
                            </div>
                            <div className={cl.panelItemFile}>
                                <Image src={main5} />
                            </div>
                            <Divider />
                        </div>
                    </div>
                </Panel>

                <Panel header={<span className={cl.panelItemTextHeader}>{t("textSupport12")}</span>} key="PanelSupport2" className={cl.panelStat}>
                    <div className={cl.panel1Inst}>
                        <div >
                            <div className={cl.panelItemText}>{t("textSupport13")}</div>
                            <div className={cl.panelItemFile}><Image src={sup1} /></div>
                            <div className={cl.panelItemFile}><Image src={sup2} /></div>
                            <Divider />
                            <div className={cl.panelItemText}>{t("textSupport14")}</div>
                            <div className={cl.panelItemFile}><Image src={sup3} /></div>
                            <Divider />
                            <div className={cl.panelItemText}>{t("textSupport15")}</div>
                            <div className={cl.panelItemFile}><Image src={sup4} /></div>
                            <Divider />
                            <div className={cl.panelItemText}>{t("textSupport16")}</div>
                            <div className={cl.panelItemFile}><Image src={sup5} /></div>
                            <Divider />
                            <div className={cl.panelItemText}>
                                <div>{t("textSupport17")}</div>
                                <div>- {t("textSupport18")}</div>
                                <div>- {t("textSupport19")}</div>
                                <div>- {t("textSupport20")}</div>
                                <div>- {t("textSupport21")}</div>
                                <div>- {t("textSupport22")}</div>
                            </div>
                            <div className={cl.panelItemFile}><Image src={sup6} /></div>
                            <Divider />
                            <div className={cl.panelItemText}>- {t("textSupport23")}</div>
                            <div className={cl.panelItemFile}><Image src={sup7} /></div>
                            <Divider />
                            <div className={cl.panelItemText}>- {t("textSupport24")}</div>
                            <div ><Image src={sup8} /></div>
                            <Divider />
                            <div className={cl.panelItemText}>- {t("textSupport25")}</div>
                            <div className={cl.panelItemFile}><Image src={sup9} /></div>
                            <Divider />
                            <div className={cl.panelItemText}>- {t("textSupport26")}</div>
                            <div className={cl.panelItemFile}><Image src={sup10} /></div>
                            <Divider />
                        </div>
                    </div>
                </Panel>
            </Collapse>

        </>

    )
}

{/* , пишите о проблемах и багах в приложении. */ }
{/* <div className={cl.inWork}>{t('inDevelop')}</div> */ }

{/* <div className="form">
                <Form
                    name="validate_other"
                    onFinish={onCommentSupportCreate}
                >
                    <Form.Item
                        name="commentSupport">
                        <input  placeholder="Расскажите о своих проблемах" className={cl.textTask}/>
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Отправить
                        </Button>
                    </Form.Item>
                </Form>
                <br />
                {commentsSupport &&
                    <div className={cl.task}>
                        <div> {
                            commentsSupport.map((h, index) => <li key={h._id} className={cl.message}>
                                {index + 1}.<span className={cl.nameAnimal}><b>Сообщение</b>: {h.comment} <b>Время</b>: {h.timeStamp} <b>Статус</b>: {h.status}</span>
                                <Button className={cl.btnDelete} onClick={() => { onDelete(h._id) }} shape="circle" icon={<DeleteFilled />} danger>
                                </Button>
                            </li>)
                        }
                        </div>
                    </div>
                }
            </div> */}