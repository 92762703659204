import { IAnimal } from '../types/animal';
import { AxiosResponse } from "axios";
import $api from "../api/axiosConfig";
import { AnimalFotoResponse, AnimalResponse } from '../models/response/AnimalResponse';

export default class AnimalService {
    static async addAnimal(animal: IAnimal, userId: string): Promise<AxiosResponse<AnimalResponse>> {
        const createAnimal = {
            animal: animal,
            userId: userId
        }
        return $api.post('animal/create', createAnimal);
    }
    static async uploadFile(userId: string, fileData: any): Promise<AxiosResponse<AnimalFotoResponse>> {
        //   console.log('userId fileData : ', userId, fileData)
      //  console.log('fileData: ', fileData)
      //  console.log('fileData.name: ', fileData.name)
        const formData = new FormData();
        formData.append('userId', userId);
        formData.append('photoAnimal', fileData.name);
        formData.append('photoAnimalFile', fileData);
        // formData.append('file', fileToUpload, fileToUpload.name);
        return $api.post('animal/uploadFoto', formData);
    }
    static async fetchAnimals(userId: string): Promise<AxiosResponse<AnimalResponse>> {

        return $api.get('animal/animals/' + userId);
    }

    static async fetchAnimal(animalId: string): Promise<AxiosResponse<AnimalResponse>> {
       // console.log('animalId: ', animalId)
        return $api.get('animal/getAnimal/' + animalId);
    }

    static async deleteAnimal(animalId: string): Promise<AxiosResponse<AnimalResponse>> {
        return $api.delete('animal/delete/' + animalId);
    }
    static async editAnimal(animal: IAnimal, userId: string): Promise<AxiosResponse<AnimalResponse>> {
        //  console.log('editAnimal:', animal)
        const editAnimal = {
            animal: animal,
            userId: userId
        }
        return $api.patch('animal/editAnimal/', { editAnimal });
    }
}

